import React from "react";
import Navegacion from "../components/Navegacion/Navegacion";
import Footer from "../components/Footer/Footer";
import IndicadorPagina from "../components/indicadorPagina/IndicadorPagina";
import BuscadorServicios from "../components/servicios/buscadorServicios/BuscadorServicios";
import BotonesServicios from "../components/servicios/botonesServicios/BotonesServicios";
import CustomButton from "../components/common/CustomButton";
import Frase from "../components/frase/Frase";
import TituloPagina from "../components/tituloPagina/TituloPagina";
import TituloSeccion from "../components/common/TituloSeccion";
import { useNavigate } from "react-router-dom";
import {Helmet} from 'react-helmet';

export default function Servicios() {
    const navigate = useNavigate();

    return (
        <div>
      <Helmet>
        <title>Servicios - Cosinte</title>
        <link
          rel="canonical"
          href="https://www.cosinte.com/servicios"
        />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:title" content="Servicios - Cosinte" />
        <meta
          property="og:url"
          content="https://www.cosinte.com/servicios"
        />
        <meta property="og:image" content="../../assets/logo-cosinte-blanco.png" />
        <meta property="og:description" content="¿QUÉ HACEMOS?
        Auditamos procesos, detectamos riesgos, calculamos las pérdidas, diseñamos estrategias y ejecutamos planes de acción." />
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-999486799/TKebCLTrkdYYEM_qy9wD'});`}</script>
      </Helmet>
            <Navegacion></Navegacion>
            <TituloPagina titulo={"SERVICIOS"} minHeight="100vh"></TituloPagina>
            <TituloSeccion
                className="mt-5"
                titulo={"¿QUÉ HACEMOS?"}
                subtitulo={
                    <>
                        Auditamos procesos, detectamos riesgos, calculamos las
                        pérdidas, <br /> diseñamos estrategias y ejecutamos
                        planes de acción.
                    </>
                }
                tituloSize={"var(--fs-heading-xl)"}
                subtituloSize={"var(--fs-heading-md)"}
                isLeftAlign={true}
            />
            <BuscadorServicios />
            <BotonesServicios />
            <div className="d-flex flex-column align-items-center">
                <div className="mt-5">
                    <CustomButton
                        texto={"¿QUIERE IMPLEMENTAR SAGRILAFT?"}
                        size={"lg"}
                        onClick={() => {
                            navigate("/servicios/analisis-sargrilaft/");
                        }}
                    />
                </div>
                <IndicadorPagina
                    rutas={["Inicio", "Servicios"]}
                ></IndicadorPagina>
            </div>
            <Frase></Frase>
            <Footer></Footer>
        </div>
    );
}
