import React, { useEffect, useState } from "react";
import ArticleSelect from "../ArticleSelect";
import { fetchAPI } from "../../../utils/fetch-api";
import "./BlogLayout.scss";
import Navegacion from "../../Navegacion/Navegacion";
import Footer from "../../Footer/Footer";
import { Container } from "react-bootstrap";
import TituloPagina from "../../tituloPagina/TituloPagina";
import TituloSeccion from "../../common/TituloSeccion";
import { useParams } from "react-router-dom";

async function fetchSideMenuData(filter) {
    try {
        const token = process.env.REACT_APP_STRAPI_API_TOKEN;
        const options = { headers: { Authorization: `Bearer ${token}` } };

        const categoriesResponse = await fetchAPI(
            "/categories",
            { populate: "*" },
            options
        );
        const articlesResponse = await fetchAPI(
            "/articles",
            filter
                ? {
                    filters: {
                        categories: {
                            name: filter,
                        },
                    },
                }
                : {
                },
            options
        );

        return {
            articles: articlesResponse.data,
            categories: categoriesResponse.data,
        };
    } catch (error) {
        console.error(error);
    }
}

function BlogLayout({ children }) {
    const [categories, setCategories] = useState([]);
    const [articles, setArticles] = useState([]);
    const [header, setHeader] = useState({
        titulo: "",
        subtitulo: ""
    });
    const params = useParams();

    useEffect(() => {
        async function fetchData() {
            const { category } = params;
            console.log(category);
            const data = await fetchSideMenuData(category);
            if (data) {
                setCategories(data.categories);
                setArticles(data.articles);
                console.log(data);
            }
        }

        fetchData();
    }, [params]);

    return (
        <>
            <Navegacion></Navegacion>
            <TituloPagina titulo="BLOG"></TituloPagina>
            <TituloSeccion
                className="mt-5"
                titulo={header.titulo}
                tituloSize={"var(--fs-heading-xl)"}
                subtituloSize={"var(--fs-heading-md)"}
                isLeftAlign={true}
                subtitulo={header.subtitulo}
            ></TituloSeccion>

            <Container className="blog__layout pb-5">
                <div className="row">
                    <div className="col-12 col-md-8">{React.cloneElement(children, { setHeader })}</div>
                    <aside className="col-12 col-md-4">
                        <ArticleSelect
                            categories={categories}
                            articles={articles}
                            params={params}
                        />
                    </aside>
                </div>
            </Container>
            <Footer></Footer>
        </>
    );
}

export async function generateStaticParams() {
    const token = process.env.NEXT_PUBLIC_STRAPI_API_TOKEN;
    const path = `/articles`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    const articleResponse = await fetchAPI(
        path,
        {
            populate: ["category"],
        },
        options
    );

    return articleResponse.data.map((article) => ({
        slug: article.attributes.slug,
        category: article.attributes.slug,
    }));
}

export default BlogLayout;
