import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "../common/Button";

function NuevoComentario({ crearComentario, idHilo }) {
    const [comentario, setComentario] = useState("");
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [comentarioError, setComentarioError] = useState("");
    const [nombreError, setNombreError] = useState("");
    const [emailError, setEmailError] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        let valid = true;

        if (comentario.trim() === "") {
            setComentarioError("El comentario es obligatorio");
            valid = false;
        } else {
            setComentarioError("");
        }

        if (nombre.trim() === "") {
            setNombreError("El nombre es obligatorio");
            valid = false;
        } else {
            setNombreError("");
        }

        if (email.trim() === "") {
            setEmailError("El correo electrónico es obligatorio");
            valid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setEmailError("El correo electrónico no es válido");
            valid = false;
        } else {
            setEmailError("");
        }

        if (valid) {
            crearComentario(nombre, email, comentario, idHilo);
            setComentario("");
            setNombre("");
            setEmail("");
        }
    };

    return (
        <div className="new__comment">
            <span className="new__comment__title">Publica un comentario</span>
            <p className="new__comment__description">
                Tu dirección de correo electrónico no será publicada. Los campos
                obligatorios están marcados con *
            </p>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col md={12}>
                        <textarea
                            type="text"
                            placeholder="Comentario*"
                            value={comentario}
                            onChange={(e) => setComentario(e.target.value)}
                        />
                        {comentarioError && (
                            <div className="error">{comentarioError}</div>
                        )}
                    </Col>
                    <Col md={6} className="mt-3 mb-4">
                        <input
                            type="text"
                            placeholder="Nombre*"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        {nombreError && (
                            <div className="error">{nombreError}</div>
                        )}
                    </Col>
                    <Col md={6} className="mt-3 mb-4">
                        <input
                            type="text"
                            placeholder="Correo electrónico*"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && (
                            <div className="error">{emailError}</div>
                        )}
                    </Col>
                </Row>
                <Button type="submit">Enviar comentario</Button>
            </form>
        </div>
    );
}

export default NuevoComentario;