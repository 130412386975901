import React from "react";
import TituloSeccion from "../common/TituloSeccion";
import { Row, Col, Container } from "react-bootstrap";
import CustomCard from "../common/CustomCard";
import { Camera } from "lucide-react";
import {
  ShieldCheck,
  ActivitySquare,
  ClipboardList,
  Search,
} from "lucide-react";

const FECHA_FUNDACION = 1997;

export default function Cosinte() {

  const calcularAnosExperiencia = () => {
    const fechaActual = new Date().getFullYear();
    return fechaActual - FECHA_FUNDACION;
  }
  
  return (
    <div>
      <TituloSeccion
        className="mt-5"
        titulo={"¿POR QUÉ COSINTE?"}
        tituloSize={"var(--fs-heading-xl)"}
        subtituloSize={"var(--fs-heading-md)"}
        isLeftAlign={true}
        subtitulo={
          <>
            Somos una organización con {calcularAnosExperiencia()} años de experiencia, especializada en la protección a todos los
            niveles. <br />
            Controlamos y prevenimos las pérdidas de su empresa.
          </>
        }
      ></TituloSeccion>
      <Container className="mt-5 pt-5">
        <Row className="pb-5">
          <Col
            xs={12}
            md={6}
            xl={3}
            className="mt-4 d-flex justify-content-center"
          >
            <CustomCard
              titulo={"Diagnóstico"}
              descripcion={
                "Le indicaremos cuales son los verdaderos riesgos a los que está expuesta su empresa"
              }
            >
              <Search />
            </CustomCard>
          </Col>
          <Col
            xs={12}
            md={6}
            xl={3}
            className="mt-4 d-flex justify-content-center"
          >
            <CustomCard
              titulo={"Evaluación"}
              descripcion={
                "Clasificaremos, analizaremos y calcularemos los potenciales peligros de cada proceso dentro de su organización, para generar un plan estratégico preciso"
              }
            >
              <ClipboardList />
            </CustomCard>
          </Col>
          <Col
            xs={12}
            md={6}
            xl={3}
            className="mt-4 d-flex justify-content-center"
          >
            <CustomCard
              titulo={"Implementación"}
              descripcion={
                "Generaremos una Cultura de Seguridad funcional ajustada a sus necesidades"
              }>
              <ShieldCheck />
            </CustomCard>
          </Col>
          <Col
            xs={12}
            md={6}
            xl={3}
            className="mt-4 d-flex justify-content-center"
          >
            <CustomCard
              titulo={"Monitoreo"}
              descripcion={
                "Durante todo el proceso, contará con nuestro acompañamiento y retroalimentación constante"
              }
            >
              <ActivitySquare />
            </CustomCard>
          </Col>
        </Row>
      </Container>
      <TituloSeccion
        className="titulo__seccion"
        icono="\e033 \e033 \e033 \e033 \e033"
        iconoSize={"56px"}
        titulo={"NUESTRA EXPERIENCIA HABLA POR NOSOTROS"}
        tituloSize={"24px"}
      ></TituloSeccion>
    </div>
  );
}
