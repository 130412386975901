import React, { useRef } from "react";
import TarjetaHorizontal from "../tarjetas/TarjetaHorizontal";
import inteligencia from "../../assets/img/inteligencia.webp";
import operaciones from "../../assets/img/operaciones.webp";
import investigaciones from "../../assets/img/investigaciones.webp";
import analisis from "../../assets/img/analisis.webp";
import servicios from "../../assets/img/servicios.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectFade, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "./Portafolio.scss";

export default function Portafolio() {
    const swiperRef = useRef(null);

    return (
        <div>
            <Swiper
                ref={swiperRef}
                slidesPerView={1}
                modules={[Navigation, Pagination, A11y, EffectFade, Autoplay]}
                pagination={{
                    clickable: true,
                    bulletClass: "swiper-pagination-bullet",
                    currentClass: "swiper-pagination-bullet-active",
                }}
                effect={"fade"}
                loop={true}
                navigation={true}
            >
                <SwiperSlide className="panel">
                    <TarjetaHorizontal
                        titulo="Servicios"
                        imagen={servicios}
                        scrollIndicator={true}
                        subtitulo={
                            <p>
                                Creemos en el trabajo constante y ponemos a su
                                disposición todo nuestro conocimiento
                            </p>
                        }
                    />
                </SwiperSlide>
                <SwiperSlide className="panel">
                    <TarjetaHorizontal
                        titulo="Inteligencia"
                        imagen={inteligencia}
                        descripcion={
                            <p>
                                Somos la primera empresa en el ámbito de
                                seguridad, en implementar una plataforma
                                tecnológica para el desarrollo de los estudios
                                de confiabilidad en la selección de personal;
                                con <strong>CIA ®2.0</strong> consultamos y
                                analizamos las anotaciones jurídicas, laborales,
                                sociales y académicas, de sus candidatos para
                                así detectar y prevenir riesgos en el proceso de
                                contratación.
                            </p>
                        }
                        boton={"Servicios de Inteligencia"}
                    />
                </SwiperSlide>
                <SwiperSlide className="panel">
                    <TarjetaHorizontal
                        titulo="Análisis"
                        imagen={analisis}
                        descripcion={
                            <p>
                                Dentro de su empresa existen procesos
                                transversales, que por sus vulnerabilidades
                                ocultas podrían estar afectando su negocio.
                                Realizamos el diagnóstico minucioso y preciso
                                del estado de riesgo de su compañía; nuestra
                                plataforma <strong>CIA ®2.0</strong> optimizará
                                su administración de riesgos.
                            </p>
                        }
                        boton={"Servicios de Análisis"}
                    />
                </SwiperSlide>
                <SwiperSlide className="panel">
                    <TarjetaHorizontal
                        titulo="Operaciones"
                        imagen={operaciones}
                        descripcion={
                            <p>
                                Nuestra metodología MC nos permite integrar el
                                diagnóstico y la evaluación de la auditoría, a
                                través de nuestra plataforma tecnológica{" "}
                                <strong>CIA ®2.0</strong> , con el fin de
                                implementar adecuadamente el Plan Estratégico de
                                Riesgos en todos los niveles de su organización.
                            </p>
                        }
                        boton={"Servicios de Operaciones"}
                    />
                </SwiperSlide>
                <SwiperSlide className="panel">
                    <TarjetaHorizontal
                        titulo="Investigaciones"
                        imagen={investigaciones}
                        descripcion={
                            <p>
                                Desarrollamos investigaciones especiales
                                privadas, esclarecemos hechos delictivos,
                                verificamos las normas que fueron o están siendo
                                violadas en su organización; adelantamos
                                investigaciones administrativas y peritajes,
                                además de desarrollar Due Dilligences en apoyo
                                al sistema de Compliance de las organizaciones,
                                para el cumplimiento de estándares de SARLAFT,
                                OEA, BASC, ISO 28000, C-TPAD.
                            </p>
                        }
                        boton={"Servicios de Investigaciones"}
                    />
                </SwiperSlide>
            </Swiper>
        </div>
    );
}
