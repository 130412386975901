import React, { useState, useEffect } from "react";
import Comment from "./Comment";
import { fetchAPI } from "../../utils/fetch-api";
import NuevoComentario from "./NuevoComentario";
import { Toaster, toast } from 'sonner'
import "./Comments.scss";

const Comments = ({articleId}) => {
    const [comments, setComments] = useState([]);

    /**
     * Crea un comentario en Strapi
     * @param {string} nombre Nombre del autor
     * @param {string} email Email del autor
     * @param {string} contenido Contenido del comentario
     */

    const crearComentario = async (nombre, email, contenido, idHilo) => {
        const token = process.env.REACT_APP_STRAPI_API_TOKEN;
        const path = `/comments/api::article.article:${articleId}`;
        const data = {
            author: {
                id:"1",
                name: nombre,
                email: email,
            },
            content: contenido
        };

        if (idHilo) {
            data.threadOf = idHilo;
        }
        const options = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };
        const responseData = await fetchAPI(path, null, options);
        if(responseData.error) {
            toast.error('Lo sentimos, no ha sido posible crear el comentario');
        }else {
            toast.success('Comentario creado correctamente');
        }
        // Actualiza los comentarios
        fetchComments();
        return responseData;
    };

    /**
     * Función para obtener los comentarios de un artículo desde Strapi
     */

    const fetchComments = async () => {
        const token = process.env.REACT_APP_STRAPI_API_TOKEN;
        const path = `/comments/api::article.article:${articleId}`;
        const urlParamsObject = {
            sort: { createdAt: "desc" },
            populate: {
                article: { fields: ["title"] },
                parentComment: { populate: "*" },
            },
        };
        const options = { headers: { Authorization: `Bearer ${token}` } };
        const responseData = await fetchAPI(path, urlParamsObject, options);
        setComments(responseData);
    };

    /**
     * Obtener la cantidad de comentarios de un artículo
     * @returns {number} Cantidad de comentarios
     */

    const getCommentsCount = () => {
        const count = comments.length;
        return `${count} ${count === 1 ? 'comentario' : 'comentarios'}`;
    }

    /**
     * Función para obtener los comentarios de un artículo desde Strapi cuando se monta el componente
     */

    useEffect(() => {
        fetchComments();
    }, [articleId]);

    return (
        <div className="comments mt-5 pt-2">
            <div className="comments__container mb-4">
                <span className="comments__title">{getCommentsCount()}</span>
                {comments?.map((comment, key) => {
                    return (
                        <Comment
                            key={key}
                            comment={comment}
                            crearComentario={crearComentario}
                            nivel={0}
                        />
                    );
                })}
            </div>
            <NuevoComentario crearComentario={crearComentario}  />
            <Toaster richColors position="bottom-center"/>
        </div>
    );
};

export default Comments;
