import React, { useState } from "react";
import Button from "../common/Button";
import NuevoComentario from "./NuevoComentario";
import { formatDate } from "../../utils/api-helpers";
import {ChevronDown, ChevronUp} from "lucide-react";

const Comment = ({ comment, crearComentario, nivel }) => {
    const { content, id, children: childCommments, createdAt, author } = comment;
    const [show, setShow] = useState(false);
    const [showAddComponet, setShowAddComponet] = useState(false);
    const paddingLeft = nivel * 24;

    /**
     * Funcion que retorna las iniciales del nombre del autor
    * @param {string} name Nombre del autor
    * @returns {string} Iniciales del nombre del autor
    * @example getInitials("John Doe") // JD
    * @example getInitials("John") // J
    * @example getInitials("John Doe Doe") // JD
    * @example getInitials("John Doe Doe Doe") // JD
    */

    const getInitials = (name) => {
        const splitName = name.split(" ");
        const initials = splitName.reduce(
            (accumulator, currentValue, currentIndex) => {
                if (currentIndex === 0 || currentIndex === splitName.length - 1) {
                    return accumulator + currentValue.charAt(0);
                }
                return accumulator;
            },
            ""
        );
        return initials;
    }

    return (
        <div className="comment">
            <div className="comment__inner" style={{ paddingLeft }}>
                <div
                    className="comment__avatar"
                    alt="avatar"
                >
                    {author?.name ? (
                        getInitials(author.name)
                    ) : (getInitials("AN"))}
                </div>
                <div className="comment__info">
                    <div className="comment__header">
                        <span className="comment__author">
                            {author?.name || "Anónimo"}
                        </span>
                        <span className="comment__date">{formatDate(createdAt)}</span>
                    </div>
                    <div className="comment__text">{content}</div>
                    <div className="comment__actions">
                        <div className="comment__add">
                            {showAddComponet ? (
                                <>
                                    <span className="comment__cancel pb-2" onClick={() => setShowAddComponet(false)}>Cancelar</span>
                                    <NuevoComentario idHilo={id} crearComentario={crearComentario} />
                                </>
                            ) : (
                                <Button
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "0.7rem",
                                    }}
                                    variant="blog"
                                    onClick={() => setShowAddComponet(true)}
                                >
                                    Agregar respuesta
                                </Button>
                            )}
                        </div>
                        {childCommments?.length > 0 && (
                            <span
                                onClick={() => setShow((show) => !show)}
                                className="comment__show"
                            >
                                {show
                                    ? "Ocultar respuestas"
                                    : "Mostrar respuestas"}

                                {show ? (
                                    <ChevronUp size={20} />
                                ) : (
                                    <ChevronDown size={20} />
                                )}

                            </span>
                        )}
                    </div>
                </div>
            </div>
            {show &&
                childCommments?.map((childCommentEl, key) => {
                    return (
                        <Comment
                            key={key}
                            comment={childCommentEl}
                            crearComentario={crearComentario}
                            nivel={nivel + 1}
                        />
                    );
                })}
        </div>
    );
};

export default Comment;
