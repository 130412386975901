import qs from "qs"; //Librería para crear query strings
import { getStrapiURL } from "./api-helpers"; //Función para obtener la URL de Strapi

/**
 * Hace una llamada a la API de Strapi
 * @param {*} path ruta de la API de Strapi, por ejemplo, /articles
 * @param {*} urlParamsObject objeto con los parámetros de la URL, por ejemplo, sort, populate, etc. 
 * @param {*} options opciones de la llamada a la API, por ejemplo, headers de autenticación
 * @returns 
 */

export async function fetchAPI(
  path,
  urlParamsObject = {},
  options = {}
) {
  try {
    // Mezcla las opciones por defecto con las opciones pasadas como parámetro
    const mergedOptions = {
      next: { revalidate: 60 },
      headers: {
        "Content-Type": "application/json",
      },
      ...options,
    };

    // Crea la URL de la API de Strapi
    const queryString = qs.stringify(urlParamsObject);
    const requestUrl = `${getStrapiURL(
      `/api${path}${queryString ? `?${queryString}` : ""}`
    )}`;

    // Hace la llamada a la API de Strapi
    const response = await fetch(requestUrl, mergedOptions);
    const data = await response.json();
    return data;

  } catch (error) {
    console.error(error);
    throw new Error(`Please check if your server is running and you set all the required tokens.`);
  }
}