import React from 'react';
import Testimonio from '../../assets/img/testimonio-cosinte.jpg';
import Fenalco from '../../assets/img/fenalco-logo2.png';
import Yanbal from '../../assets/img/yanbal-16-22.png';
import Oea from '../../assets/img/oea-106-2.png';
import {ParallaxBanner} from 'react-scroll-parallax';
import {Carousel} from 'react-bootstrap';
import {Fade} from 'react-awesome-reveal';
import './Opiniones.scss';

export default function Opiniones() {
  return (
    <div>
      <div>
        <ParallaxBanner layers={[{image: Testimonio, speed: -15}]}>
          <div className="carousel__container">
            <h4 className="opinions__title">
              QUÉ OPINAN NUESTROS CLIENTES SOBRE NOSOTROS
            </h4>
            <Carousel indicators={false}>
              <Carousel.Item>
                <Fade duration="3000" triggerOnce={true}>
                  <div className="opinion__container">
                    <img
                      src={Fenalco}
                      alt="opinion"
                      className="opinion__img d-none d-xl-block fluid-img"
                    />
                    <div className="opinion__text">
                      <h5 className="opinion__person text-center text-xl-start">
                        Juan Esteban Orrego Calle - Director Ejecutivo - Fenalco
                        Bogotá
                      </h5>
                      <p className="opinion__description text-center text-xl-start">
                        El diseño e implementación del Plan Estratégico de
                        Seguridad mediante la Gerencia de Riesgos, por parte de
                        Cosinte Ltda en FENALCO, ha logrado mitigar de manera
                        ostensible los riesgos de la organización,
                        convirtiéndose en parte fundamental para el desarrollo
                        sostenible de nuestra labor a nivel gremial.
                      </p>
                    </div>
                  </div>
                </Fade>
              </Carousel.Item>
              <Carousel.Item>
                <Fade duration="3000" triggerOnce={true}>
                  <div className="opinion__container">
                    <img
                      src={Oea}
                      alt="opinion"
                      className="opinion__img d-none d-xl-block fluid-img"
                    />
                    <div className="opinion__text">
                      <h5 className="opinion__person text-center text-xl-start">
                        Capitan de Navío John Fabio Giraldo Gallo - Director de
                        Incorporación - Armada Nacional de Colombia
                      </h5>
                      <p className="opinion__description text-center text-xl-start">
                        A través de la ejecución del Convenio de Cooperacion
                        Técnica para el desarrollo de los ESP – Estudios de
                        Seguridad Personal aplicados a los aspirantes en la fase
                        de selección. COSINTE Ltda., mediante su proceso y
                        plataforma tecnológica, ha contribuido a que la gestión
                        sea más confiable y oportuna, además de, prevenir los
                        riesgos en un procedimiento tan sensible para la
                        entidad, como el de incorporación.
                      </p>
                    </div>
                  </div>
                </Fade>
              </Carousel.Item>
              <Carousel.Item>
                <Fade duration="3000" triggerOnce={true}>
                  <div className="opinion__container">
                    <img
                      src={Yanbal}
                      alt="opinion"
                      className="opinion__img d-none d-xl-block fluid-img"
                    />
                    <div className="opinion__text">
                      <h5 className="opinion__person text-center text-xl-start">
                        Yanbal - Departamento de Recursos Humanos
                      </h5>
                      <p className="opinion__description text-center text-xl-start">
                        Consideramos que Cosinte Ltda., es un valioso aliado
                        estratégico en la incorporación y validación periódica
                        de nuestros colaboradores en la Cadena de
                        Abastecimiento, mediante los Estudios de Confiabilidad y
                        la metodología MC, la cual es una valiosa herramienta
                        vanguardista. Esto, nos permite día a día, aportar
                        crecimiento con seguridad, cumpliendo con los estándares
                        BASC e ISO 28000, en nuestro comercio exterior y la
                        distribución local.
                      </p>
                    </div>
                  </div>
                </Fade>
              </Carousel.Item>
            </Carousel>
          </div>
        </ParallaxBanner>
      </div>
    </div>
  );
}
