import React from "react";
import Button from "../common/Button";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import "./Frase.scss";

export default function Frase({variant}) {
  const navigate = useNavigate();
  return (
    <Container fluid className={`container__frase ${variant}`}>
      <div className="inner__container__frase">
        <Zoom  triggerOnce={true} className="d-flex flex-column justify-content-center align-items-center">
          <p className="frase__text">Deje su seguridad en nuestras manos</p>
          <div>
            <Button
              onClick={() => {
                navigate("/contactenos");
              }}
            >
              Contáctenos
            </Button>
          </div>
        </Zoom>
      </div>
    </Container>
  );
}
