import React, { useEffect, useState } from "react";
import { Row, Col, Container, Nav, Navbar, Image } from "react-bootstrap";
import Logo from "../../assets/logo-cosinte-blanco.png";
import Cia from "../../assets/icon/cia.svg";
import { ChevronDown } from "react-bootstrap-icons";
import { NavLink, Link, useLocation } from "react-router-dom";
import { ReactComponent as Linkedin } from "../../assets/icon/linkedin.svg";
import { ReactComponent as Facebook } from "../../assets/icon/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icon/instagram.svg";
import { ReactComponent as Correo } from "../../assets/icon/correo.svg";
import { Slide } from "react-awesome-reveal";
import "./Navegacion.scss";

function Navegacion({ transparent }) {
  let location = useLocation();
  const [isSolid, setIsSolid] = useState(false);

  useEffect(() => {
    //Close the navbar when location changes, but only if it is open
    if (document.querySelector(".navbar-collapse.show")) {
      document.querySelector(".navbar-toggler").click();
    }
  }, [location]);

  /**
   * Cambiar el color de la barra de navegación cuando se hace scroll
   */

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) {
        setIsSolid(true);
      } else {
        setIsSolid(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      className={`nav-body navbar-nav-scroll ${isSolid ? "solid" : ""} ${
        transparent ? "transparent" : "solid"
      }`}
      expand="lg"
      fixed="top"
    >
      <Container fluid>
        <Navbar.Brand to="/inicio">
          <Image src={Logo} className="brand__navbar" alt="Logo Cosinte LTDA" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav .navbar-nav">
          <Nav className="nav-services mx-auto" activeKey={location.pathname}>
            <NavLink
              to="/inicio"
              activeClassName="active"
              className={"nav-link relative-link"}
            >
              Inicio
            </NavLink>
            <NavLink
              to="/nosotros"
              as={Link}
              activeClassName="active"
              className={"nav-link relative-link"}
            >
              Nosotros
            </NavLink>
            <NavLink
              className="dropdown__title nav-link no-hover "
              to="/servicios"
              as={Link}
              activeClassName="active"
            >
              <span>
                Servicios <ChevronDown size={10}></ChevronDown>
              </span>
              <div className="dropdown__servicios">
                <Row>
                  <Col xs={12} lg={3}>
                    <span className="dropdown__list__title"> Análisis</span>
                    <ul className="dropdown__list">
                      <NavLink
                        to="/servicios/analisis-sargrilaft/"
                        activeClassName="active"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item" as={Link}>
                          Análisis de Procesos SARLAFT/SAGRILAFT
                        </li>
                      </NavLink>

                      <Link
                        to="/servicios/analisis-de-procesos-oea/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Análisis de Procesos OEA
                        </li>
                      </Link>

                      <Link
                        to="/servicios/estudio-de-seguridad-fisica/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Estudio de Seguridad Física
                        </li>
                      </Link>
                      <Link
                        to="/servicios/analisis-de-procesos/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Análisis de Procesos</li>
                      </Link>

                      <Link
                        to="/servicios/panorama-de-riesgos/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Panorama de Riesgos</li>
                      </Link>

                      <Link
                        to="/servicios/evaluacion-de-riesgo-personal/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Evaluación de Riesgo Personal
                        </li>
                      </Link>
                    </ul>
                  </Col>
                  <Col xs={12} lg={3}>
                    <span className="dropdown__list__title"> Inteligencia</span>
                    <ul className="dropdown__list">
                      <Link
                        to="/servicios/poligrafo-de-rutina/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Polígrafo de Rutina</li>
                      </Link>

                      <Link
                        to="/servicios/poligrafo-de-seleccion/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Polígrafo de Selección
                        </li>
                      </Link>

                      <Link
                        to="/servicios/visita-domiciliaria/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Visita Domiciliaria</li>
                      </Link>

                      <Link
                        to="/servicios/comprobaciones-de-lealtad/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Comprobaciones de Lealtad
                        </li>
                      </Link>

                      <Link
                        to="/servicios/estudio-de-confiabilidad-seleccion/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Estudio de Confiabilidad Selección
                        </li>
                      </Link>
                    </ul>
                  </Col>
                  <Col xs={12} lg={3}>
                    <span className="dropdown__list__title">
                      {" "}
                      Investigaciones
                    </span>
                    <ul className="dropdown__list">
                      <Link
                        to="/servicios/investigaciones-por-siniestro/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Investigaciones por Siniestro
                        </li>
                      </Link>

                      <Link
                        to="/servicios/poligrafo-especifico/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Polígrafo Específico</li>
                      </Link>

                      <Link
                        to="/servicios/compliance/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Compliance</li>
                      </Link>

                      <Link
                        to="/servicios/investigaciones-administrativas/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Investigaciones Administrativas
                        </li>
                      </Link>

                      <Link
                        to="/servicios/contravigilancia/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Contravigilancia</li>
                      </Link>
                    </ul>
                  </Col>
                  <Col xs={12} lg={3}>
                    <span className="dropdown__list__title"> Operaciones</span>
                    <ul className="dropdown__list">
                      <Link
                        to="/servicios/gerencia-de-riesgos/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Gerencia de Riesgos</li>
                      </Link>

                      <Link
                        to="/servicios/interventorias/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Interventorías</li>
                      </Link>

                      <Link
                        to="/servicios/redes-de-informacion/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Redes de Información</li>
                      </Link>

                      <Link
                        to="/servicios/capacitaciones-y-talleres/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">
                          Capacitaciones y Talleres
                        </li>
                      </Link>

                      <Link
                        to="/servicios/acompanamiento-oea/"
                        className="dropdown__link"
                      >
                        <li className="dropdown__item">Acompañamiento OEA</li>
                      </Link>
                    </ul>
                  </Col>
                </Row>
              </div>
            </NavLink>
            <NavLink
              to="/contactenos"
              activeClassName="active"
              className={"nav-link relative-link"}
            >
              Contáctenos
            </NavLink>
            <NavLink
              to="/trabaje-con-nosotros"
              activeClassName="active"
              className={"nav-link relative-link"}
            >
              Trabaje con nosotros
            </NavLink>
            <NavLink
              to="/blog"
              activeClassName="active"
              className={"nav-link relative-link"}
            >
              Blog
            </NavLink>
          </Nav>
          <div className="logos__container">
            <div className="social__container">
              <Link
                to={"https://www.instagram.com/cosinteltda/"}
                target="_blank"
              >
                <Instagram className="social__icon social__icon--contraste lg"></Instagram>
              </Link>
              <Link
                to={"https://www.facebook.com/Cosinteltda/"}
                target="_blank"
              >
                <Facebook className="social__icon lg"></Facebook>
              </Link>
              <Link
                to={"https://www.linkedin.com/company/cosinte-ltda./"}
                target="_blank"
              >
                <Linkedin className="social__icon lg"></Linkedin>
              </Link>
            </div>
            <div className="ml-auto d-flex align-items-center">
              <Slide direction="down">
                <Link to="https://www.ciacosinte.site" target="_blank">
                  <div className="logo__cia mx-3">
                    <Image src={Cia} className="navbar__icon cia__icon"  alt="CIA" />
                  </div>
                </Link>
              </Slide>
              <Link to="https://emailmg.globat.com/roundcube/" target="_blank">
                <Correo className="social__icon social__icon--contraste lg"></Correo>
              </Link>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navegacion;
