import React from 'react'
import styled from 'styled-components';
import { PatchQuestionFill } from 'react-bootstrap-icons';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';

export default function NotFoundMessage() {

    const navigate = useNavigate();
  return (
      <Container>
        <PatchQuestionFill size={64} color="var(--color-primary-500)" />
        <Title>Página no encontrada</Title>
        <Description>La página que buscas no existe o no se encuentra disponible.</Description>
        <Button onClick={() => navigate('/')} style={{marginTop: '2rem'}}>
            Volver al inicio
        </Button>
      </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6rem 0;
    background-color: var(--color-light-gray);
}`;

const Title = styled.h2`
    color: var(--color-primary-700);
    font-size: var(--fs-heading-xl);
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
}`;

const Description = styled.p`
    color: var(--color-font-light);
    font-size: var(--fs-heading-sm);
    text-align: center;
    margin-top: .5rem;
}`;





