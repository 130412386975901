import React from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import servicios from '../../data/servicios.json';
import './IndicadorPagina.scss';

export default function IndicadorPagina({rutas}) {
  const location = useLocation();

  /**
   * Hacer split de la ruta actual y los convierte en un arreglo
   */
  if (!rutas) {
    rutas = location.pathname.split('/');
    rutas.pop();
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function searchNameinData() {
    let name = '';
    servicios.forEach(servicio => {
      if (servicio.location === location.pathname) {
        name = servicio.nombre;
      }
    });
    if (name) {
      return name;
    } else {
      return rutas[rutas.length - 1];
    }
  }

  searchNameinData();

  return (
    <div className="d-flex justify-content-center m-5 p-3 mt-3">
      {rutas.map((ruta, index) => {
        index === 0 ? (ruta = 'Inicio') : (ruta = ruta);
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            key={index}>
            <Link
              to={`/${ruta}`}
              key={index}
              className={
                index === rutas.length - 1
                  ? 'indicador__link indicador__link--active'
                  : 'indicador__link'
              }>
              {index === rutas.length - 1
                ? searchNameinData()
                : capitalizeFirstLetter(ruta)}
            </Link>
            {index !== rutas.length - 1 && (
              <span className="indicador__separador"></span>
            )}
          </div>
        );
      })}
    </div>
  );
}
