import React from "react";
import "./MouseIndicator.scss";

export default function MouseIndicator() {
  return (
    <div className="mouse">
      <div className="mouse-icon">
        <span className="mouse-wheel"></span>
      </div>
    </div>
  );
}
