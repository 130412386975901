import React from "react";
import styled from "styled-components";
import "./InfoElement.scss";

export default function InfoElement({
  titulo,
  icono,
  children,
  iconSize,
  titleSize,
  isWhiteMode,
}) {
  return (
    <div className="info__element">
      <div className="info__element__icon">
        {icono && (
          <InfoIcon
            icono={icono}
            iconSize={iconSize}
            className={"info__icon__label"}
            isWhiteMode={isWhiteMode}
          ></InfoIcon>
        )}
      </div>
      <div className={isWhiteMode ? "info__element__text--white" : ""}>
        <Titulo titleSize={titleSize}isWhiteMode={isWhiteMode}>{titulo}</Titulo>
        {children}
      </div>
    </div>
  );
}

const InfoIcon = styled.span`
  margin: 0 0.5rem;
  font-family: "etmodules";
  font-weight: 300;
  font-size: ${({ iconSize }) => (iconSize ? iconSize : "30px")};
  color: ${({ isWhiteMode }) => (isWhiteMode ? "white" : "var(--color-primary-800)")};
  &::before {
    content: ${({ icono }) => `"${icono}"`};
  }
`;

const Titulo = styled.h4`
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: ${({ isWhiteMode }) => (isWhiteMode ? "var(--color-secondary)" : "var(--color-primary-800)")};
  font-size: ${({ titleSize }) => (titleSize ? titleSize : "16px")};
`;
