import styled from 'styled-components';
import {
  color,
  space,
  fontSize,
  buttonStyle,
  variant,
  width,
} from 'styled-system';

const buttonSize = variant({
  prop: 'size',
  key: 'buttonSizes',
});

const Button = styled.button`
  border: 0;
  outline: 0;
  transition: all 0.3s ease;
  ${color}
  ${width}
  ${space}
  ${fontSize}
  ${buttonStyle}
  ${buttonSize}
`;
Button.defaultProps = {
  variant: 'primary',
  backgroundColor: 'blue',
  size: 'medium',
};

export default Button;
