import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Form from './form/Form';
import ContactInfoAlternative from './contactInfo/ContactInfoAlternative';
import Enlaces from './enlaces/Enlaces';
import './Footer.scss';

function Footer() {
  return (
    <Container fluid>
      <div className="footer__container">
        <Row xs={1} md={1} lg={3}>
          <Col>
            <Form />
          </Col>
          <Col mb={3}>
            <ContactInfoAlternative />
          </Col>
          <Col mb={3}>
            <Enlaces />
          </Col>
        </Row>
        <div className="footer__copy text-center text-lg-start">
          <small className="footer__copy-text">
            © 2023 - Todos los derechos reservados - COSINTE LTDA
          </small>
        </div>
      </div>
    </Container>
  );
}
export default Footer;
