import React from 'react';
import styled from 'styled-components';
import Button from '../../../common/Button';
import {useNavigate} from 'react-router-dom';

export default function TarjetaBotones({icono, titulo, botones}) {
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column align-items-center">
      <Icono icono={icono}></Icono>
      <Titulo>{titulo}</Titulo>
      {botones &&
        botones.map(boton => {
          return (
            <Button
              style={{marginBottom: '1rem'}}
              key={boton.nombre}
              width="100%"
              variant="servicio"
              size="small"
              onClick={() => {
                navigate(`${boton.location}`);
              }}>
              {boton.nombre}
            </Button>
          );
        })}
    </div>
  );
}

const Titulo = styled.h4`
  font-weight: 700;
  font-size: 26px;
  color: var(--color-primary-700);
  line-height: 1.2em;
  text-align: center;
  margin-bottom: 2rem;
`;

const Icono = styled.div`
  font-family: 'etmodules';
  margin: 0 0.5rem;
  font-family: 'etmodules';
  font-size: 80px;
  color: var(--color-secondary);
  margin-bottom: 1rem;
  &::before {
    content: ${({icono}) => `"${icono}"`};
  }
`;
