import React, {useEffect, useState} from 'react';
import Navegacion from '../components/Navegacion/Navegacion';
import {useLocation} from 'react-router-dom';
import servicios from '../data/servicios.json';
import ServicioInfo from '../components/servicios/servicioInfo/ServicioInfo';
import Frase from '../components/frase/Frase';
import Footer from '../components/Footer/Footer';
import {Helmet} from 'react-helmet';

export default function Servicio() {
  const location = useLocation();
  const [servicio, setServicio] = useState(null);

  useEffect(() => {
    const servicio = buscarServicio(location);
    setServicio(servicio);
  }, [location]);

  /**
   * Método que busca el servicio en el arreglo de servicios y lo retorna
   * @param {string} location - El nombre de la ruta del servicio
   * @returns {object} - El servicio encontrado
   */

  const buscarServicio = location => {
    const servicio = servicios.find(
      servicio => servicio.location === location.pathname,
    );
    return servicio;
  };

  /**
   * Indica las páginas de servicio que se deben convertir en conversiones de Google Ads
   * @type {string[]}
   */

  const servicesToCoversion = [
    '/servicios/analisis-sargrilaft/',
    '/servicios/analisis-de-procesos-oea/'
  ]

  return (
    <div>
      <Helmet>
        <title>{servicio?.seo?.title}</title>
        <meta name="description" content={servicio?.descripcion} />
        <meta property="og:type" content="article"></meta>
        <meta property="og:title" content={servicio?.seo?.title}></meta>
        <meta property="og:url" content={servicio?.seo?.og_url}></meta>
        <link rel="canonical" href={servicio?.seo?.canonical}></link>
        <meta
          property="article:modified_time"
          content={servicio?.seo?.article_modified_time}></meta>

        <script type="application/ld+json">
          {JSON.stringify(servicio?.ldJson)}
        </script>
        {servicesToCoversion.includes(location.pathname) && (
            <script>{`gtag('event', 'conversion', {'send_to': 'AW-999486799/TKebCLTrkdYYEM_qy9wD'});`}</script>
        )}
      </Helmet>
      <Navegacion></Navegacion>
      <ServicioInfo servicio={servicio}></ServicioInfo>
      <Frase></Frase>
      <Footer></Footer>
    </div>
  );
}
