import React from "react";
import styled from "styled-components";
import confiabilidad from "../../../assets/img/confiabilidad.gif";
import seguridadIntegral from "../../../assets/img/seguridad_integral.gif";
import calidad from "../../../assets/img/calidad.gif";
import Button from "../../common/Button";
import logow3 from "../../../assets/img/w3logo.png";
import Pdf_Tratamientos_Datos from "../../../assets/docs/Politica-de-Tratamiento-de-Datos-2023.pdf";
import Pdf_Gestion_Integral from "../../../assets/docs/Politica-de-Gestion-Integral-2023.pdf";
import Pdf_Mision_Vision from "../../../assets/docs/Mision-y-Vision-2023.pdf";
import "./ExpandSplit.scss";

export default function ExpandSplit() {
    return (
        <ExpandSplitContainer>
            <ExpandBox>
                <h3>Confiabilidad</h3>
                <p>
                    Le garantizamos a nuestros clientes que su información y la
                    de su empresa está en manos profesionales. Nuestra gestión
                    no solo está amparada por un acuerdo de confidencialidad,
                    sino también por una política de tratamiento de datos
                    personales. Descargue nuestra Política de Tratamiento de
                    Datos Personales:
                </p>
                <Button
                    variant="hero"
                    onClick={() => {
                        window.open(Pdf_Tratamientos_Datos);
                    }}
                    target="_blank"
                >
                    Descargar
                </Button>
            </ExpandBox>
            <ExpandBox>
                <h3>Seguridad Integral</h3>
                <p>
                    Nos encargamos de aplicar la normatividad vigente, junto con
                    la metodología avalada en el ámbito de la gestión integral
                    de riesgos. Nuestro compromiso es prevenir el delito y
                    administrar el riesgo teniendo en cuenta sus necesidades.
                    Descargue nuestra Política de Gestión Integral:
                </p>
                <Button
                    variant="hero"
                    onClick={() => {
                        window.open(Pdf_Gestion_Integral);
                    }}
                    target="_blank"
                >
                    Descargar
                </Button>
            </ExpandBox>
            <ExpandBox>
                <h3>Calidad</h3>
                <p>
                    Contamos con una infraestructura propia y personal idóneo
                    para la adecuada gestión de riesgos; nuestra plataforma
                    tecnológica CIA®2.0 está desarrollada con tecnología de
                    punta, la cual garantiza una mayor eficacia y eficiencia en
                    todos nuestros procesos. Descargue nuestra Misión y Visión:
                </p>
                <Button
                    variant="hero"
                    onClick={() => {
                        window.open(Pdf_Mision_Vision);
                    }}
                    target="_blank"
                >
                    Descargar
                </Button>
            </ExpandBox>
        </ExpandSplitContainer>
    );
}

const ExpandSplitContainer = styled.div`
    width: 100%;
    margin: 0;
    display: flex;
    margin-top: 2rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const ExpandBox = styled.div`
    height: 500px;
    flex: 1;
    background-image: url(${confiabilidad});
    background-size: cover;
    transition: .5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;

    mask-image: image(url(${logow3}));

    &:nth-child(2) {
        background-image: url(${seguridadIntegral});
    }

    &:nth-child(3) {
        background-image: url(${calidad});
    }

    &:not(:last-child) {
        border-right: 1px solid rgba(255,255,255,0.2);
    }

    &:hover {
        flex: 4;
        color: white;
        border 1px solid white;
        button {
            display: block;
        }
    }

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.7);
        z-index: 0;
        transition: .5s;
    }

    &:hover::after {
        background-color: rgba(0,0,0,0.85);
    }

    h3{
        z-index: 1;
        transition: .5s;
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        padding: 0 2rem;
    }

    p{
        z-index: 1;
        padding: 0 2rem;
        font-weight: 300;
        font-size: 1.2rem;
        margin-top: .5rem;
        max-width: 700px;
    }

    &:not(:hover) p {
        display: none;
    }

    button {
        display: none;
        margin-top: 2rem;
    }

    //Handle mobile view

    @media (max-width: 768px) {
        flex: 0 1 auto;
        &:hover {
            flex: 0 1 auto;
            border: none;
        }

        &:hover::after {
            background-color: rgba(0,0,0,0.7);
            opacity: 1;
        }

        button {
            display: block;
        }

        h3 {
            font-size: 2rem;
        }

        p {
            font-size: 1rem;
            text-align: center;
        }

        &:not(:hover) p {
            display: block;
        }
    }
`;
