import React from 'react';
import styled from 'styled-components';
import { Whatsapp } from 'react-bootstrap-icons';


const FloatButton = styled.a`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  background-color: #25D366;
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 999;
`;

const WhatsAppButton = () => {
  return (
    <FloatButton
      href="https://wa.me/5716052424"
      target="_blank"
      rel="noopener noreferrer">
      <Whatsapp size={32} />
    </FloatButton>
  );
};

export default WhatsAppButton;
