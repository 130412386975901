import React, { useState, useEffect } from "react";
import { fetchAPI } from "../utils/fetch-api";
import Post from "../components/blog/Post";
import Loader from "../components/blog/Loader";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function PostAPI(props) {
    const [post, setPost] = useState(null);
    const [keywords, setKeywords] = useState(null);
    const params = useParams();

    useEffect(() => {
        async function fetchData() {
            const token = process.env.REACT_APP_STRAPI_API_TOKEN;
            const postResponse = await fetchAPI(
                "/articles",
                {
                    filters: { slug: params.slug },
                    populate: {
                        cover: { fields: ["url"] },
                        authorsBio: { fields: ["name"], populate: "avatar" },
                        categories: { fields: ["name"] },
                        blocks: { populate: "*" },
                        seo: { populate: "*" },
                    },
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setPost(postResponse.data[0]);
            props.setHeader({
                titulo: postResponse.data[0]?.attributes.title,
                subtitulo: postResponse.data[0]?.attributes.description,
            });
            const keys = postResponse.data[0].attributes?.seo?.keywords.map(keyword => keyword.keyword).join(', ');
            setKeywords(keys);
        }

        fetchData();
    }, [params]);

    if (!post) {
        return (
            <div>
                <Loader></Loader>
            </div>
        );
    }


    return (
        <>
            <Helmet>{}
                <meta name="keywords" content={keywords} />
                {post.attributes?.seo?.metaTitle ? (
                    <title>{post.attributes.seo.metaTitle}</title>
                ) : (
                    <title>{post.attributes.title}</title>
                )}
                {post.attributes?.seo?.metaDescription ? (
                    <meta
                        name="description"
                        content={post.attributes.seo.metaDescription}
                    />
                ) : (
                    <meta
                        name="description"
                        content={post.attributes.description}
                    />
                )}
                {post.attributes?.seo?.metaTitle ? ( // Open Graph / Facebook
                    <meta
                        property="og:title"
                        content={post.attributes.seo.metaTitle}
                    />
                ) : (
                    <meta
                        property="og:title"
                        content={post.attributes.title}
                    />
                )}
                {post.attributes?.seo?.metaDescription ? (
                    <meta
                        property="og:description"
                        content={post.attributes.seo.metaDescription}
                    />
                ) : (
                    <meta
                        property="og:description"
                        content={post.attributes.description}
                    />
                )}
                {post.attributes.cover && (
                    <meta
                        property="og:image"
                        content={post.attributes.cover.url}
                    />
                )}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:locale" content="es_ES" />
                <meta
                    property="article:published_time"
                    content={post.attributes.publishedAt}
                />
                <meta
                    property="article:modified_time"
                    content={post.attributes.updatedAt}
                />
                <meta
                    property="article:author"
                    content={
                        post.attributes?.authorsBio?.data?.attributes?.name
                    }
                />
                <meta
                    property="article:section"
                    content={post.attributes.categories?.data[0].attributes.name}
                />
                <meta
                    property="article:tag"
                    content={post.attributes.categories?.data[0].attributes.name}
                />
            </Helmet>
            <Post data={post} />
        </>
    );
}

export default PostAPI;
