import React, {useEffect} from 'react';
import './BuscadorServicios.scss';
import {Container} from 'react-bootstrap';
import servicios from '../../../data/servicios.json';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

export default function BuscadorServicios() {
  const [serviciosFiltrados, setServiciosFiltrados] = React.useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const search = location?.state?.search;

  useEffect(() => {
    //Scroll to top
    window.scrollTo(0, 0);
    if (search !== undefined) {
      //Buscar los servicios cuya categoria sea igual a la busqueda
      const serviciosFiltrados = servicios.filter(
        servicio => servicio.categoria === search,
      );
      setServiciosFiltrados(serviciosFiltrados);
    }
  }, [search]);

  const handleSubmit = e => {
    e.preventDefault();
    const input = e.target[0].value;
    if (input === '') {
      setServiciosFiltrados([]);
      return;
    }
    //Buscar en los servicios si cualquier clave valor contiene el input
    const serviciosFiltrados = servicios.filter(servicio => {
      const valores = Object.values(servicio);
      return valores.some(valor => {
        return valor.toString().toLowerCase().includes(input.toLowerCase());
      });
    });
    setServiciosFiltrados(serviciosFiltrados);
  };

  return (
    <div className="mt-3">
      <Container>
        <form
          className="d-flex justify-content-start buscador__container mb-5"
          onSubmit={handleSubmit}>
          <input
            placeholder="Busca tus servicios"
            className="buscador__input"></input>
          <button className="buscador__button">Búsqueda</button>
        </form>

        <div className="resultados__container">
          {serviciosFiltrados.map(servicio => (
            <article className="resultado__container">
              <h2
                className="resultado__nombre"
                onClick={() => navigate(servicio.location)}>
                {servicio.nombre}
              </h2>
              <span className="resultado__fecha">{servicio.fecha}</span>
              <p className={'resultado__descripcion'}>{servicio.descripcion}</p>
            </article>
          ))}
        </div>
      </Container>
    </div>
  );
}
