import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../common/Button";
import Loader from "../../common/Loader";
import axios from "axios";
import { Toast } from "react-bootstrap";
import politica from "../../../assets/docs/Politica-de-Tratamiento-de-Datos-2023.pdf";
import ReCAPTCHA from "react-google-recaptcha";

import "./FormTrabajeConNosotros.scss";

function Form() {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formMessage, setFormMessage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({
        nombre: "",
        ciudad: "",
        cedula: "",
        email: "",
        telefono: "",
        celular: "",
        titulo: "",
        institucion: "",
        fecha_titulo: "",
        cargo: "",
        empresa: "",
        telefono_empresa: "",
        funciones: "",
        hoja_vida: "",
    });

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setError(false);
        setSuccess(false);
        setFormMessage(null);
        if (!token) {
            setError(true);
            setFormMessage("Por favor, verifique el captcha");
            setLoading(false);
            return;
        }

        axios
            .post(
                "api/ecc_f.php",
                {
                    nombre: formData.nombre,
                    ciudad: formData.ciudad,
                    cedula: formData.cedula,
                    email: formData.email,
                    telefono: formData.telefono,
                    celular: formData.celular,
                    titulo: formData.titulo,
                    institucion: formData.institucion,
                    fecha_titulo: formData.fecha_titulo,
                    cargo: formData.cargo,
                    empresa: formData.empresa,
                    telefono_empresa: formData.telefono_empresa,
                    funciones: formData.funciones,
                    hoja_vida: formData.hoja_vida,
                    token: token,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((res) => {
                if (res.data.success) {
                    setSuccess(true);
                    setFormMessage(res.data.message);
                    setLoading(false);
                    handleReset();
                } else {
                    setError(true);
                    setFormMessage(res.data.message);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setError(true);
                setFormMessage("Error al enviar el formulario");
                setLoading(false);
            });
    };

    const handleInputChange = (e) => {
        if (e.target.type === "file") {
            setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSelectedFile = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleReset = () => {};

    /**
     * Método que se ejecuta cuando se cambia el captcha
     */

    const onChangeCaptcha = (value) => {
        setToken(value);
    };

    return (
        <div className="d-flex align-items-center align-items-lg-start flex-column">
            <form
                method="POST"
                encType="multipart/form-data"
                className={"form__equipo"}
                onSubmit={handleFormSubmit}
            >
                <Text>Información Personal:</Text>
                <input
                    className="form__input"
                    type="text"
                    placeholder="*Nombre y Apellido"
                    id="nombre"
                    name="nombre"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="text"
                    placeholder="*Ciudad de Residencia"
                    id="ciudad"
                    name="ciudad"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="number"
                    placeholder="*N° de Cedula"
                    id="cedula"
                    name="cedula"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="email"
                    placeholder="*Correo electrónico"
                    id="email"
                    name="email"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="tel"
                    placeholder="*Número de Telefono"
                    id="telefono"
                    name="telefono"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="tel"
                    placeholder="*Número de Telefono Celular"
                    id="celular"
                    name="celular"
                    onChange={handleInputChange}
                    required
                />
                <Text>Información Académica:</Text>
                <input
                    className="form__input"
                    type="text"
                    placeholder="*Título obtenido"
                    id="titulo"
                    name="titulo"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="text"
                    placeholder="*Institución académica"
                    id="institucion"
                    name="institucion"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="date"
                    id="fecha_titulo"
                    name="fecha_titulo"
                    onChange={handleInputChange}
                    required
                />
                <Text>
                    Experiencia laboral:<br></br>
                    (Ingresar la última experiencia laboral)
                </Text>
                <input
                    className="form__input"
                    type="text"
                    placeholder="*Cargo"
                    id="cargo"
                    name="cargo"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="text"
                    placeholder="*Nombre de Empresa"
                    id="empresa"
                    name="empresa"
                    onChange={handleInputChange}
                    required
                />
                <input
                    className="form__input"
                    type="tel"
                    placeholder="*Número de Teléfono"
                    id="telefono_empresa"
                    name="telefono_empresa"
                    onChange={handleInputChange}
                    required
                />
                <textarea
                    className="form__input form__input--textarea"
                    id="funciones"
                    name="funciones"
                    placeholder="*Funciones del cargo:"
                    onChange={handleInputChange}
                    required
                ></textarea>
                <Text>Adjuntar hoja de vida:</Text>
                <input
                    className="mt-2"
                    type="file"
                    id="hoja_vida"
                    name="hoja_vida"
                    accept=".pdf"
                    onChange={handleInputChange}
                    required
                />
                <div className="form__button_container mt-4">
                    <ReCAPTCHA
                        sitekey="6LfHMhIpAAAAAFZDVSu-dkAEY5WdN4BbcsHTmig3"
                        onChange={onChangeCaptcha}
                    />
                    <div>
                        <Button
                            className="form__button"
                            variant="primary"
                            type="submit"
                        >
                            {loading ? (
                                <Loader mensaje={"Enviando"}></Loader>
                            ) : (
                                "Enviar"
                            )}
                        </Button>
                    </div>
                </div>

                {error && (
                    <Toast
                        onClose={() => setError(false)}
                        show={error}
                        delay={4000}
                        autohide
                        className="toast__error"
                    >
                        <Toast.Header>
                            <strong className="me-auto">Error</strong>
                        </Toast.Header>
                        <Toast.Body>{formMessage}</Toast.Body>
                    </Toast>
                )}

                {success && (
                    <Toast
                        onClose={() => setSuccess(false)}
                        show={success}
                        delay={4000}
                        autohide
                        className="toast__success"
                    >
                        <Toast.Header>
                            <img
                                src="holder.js/20x20?text=%20"
                                className="rounded me-2"
                                alt=""
                            />
                            <strong className="me-auto">Éxito</strong>
                        </Toast.Header>
                        <Toast.Body>{formMessage}</Toast.Body>
                    </Toast>
                )}
            </form>
            <div className="legal px-5 px-lg-0">
                <span className="legal__title">•AVISO LEGAL</span>
                <p className="legal__text">
                    *Al dar clic sobre el botón de ENVIAR, estoy aceptando el
                    tratamiento de mis datos
                    <br /> personales conforme a la
                    <a
                        className="legal__link"
                        href={politica}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {" "}
                        política de tratamiento de datos personales.
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Form;

const Text = styled.h2`
    color: var(--color-primary-700);
    font-weight: bold;
    font-size: 15px;
    margin-left: 3px;
    margin-bottom: 16px;
    &:not(:first-child) {
        margin-top: 16px;
    }
`;
