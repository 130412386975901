
/**
 * Retorna la URL de Strapi API dependiendo del entorno de ejecución
 * Si se ejecuta en local, se usa la URL de Strapi local (http://localhost:1337)
 * Si se ejecuta en producción, se usa la URL de Strapi en producción (https://api.example.com)
 * @param {*} path ruta de la API de Strapi
 * @returns
 */

export function getStrapiURL(path = '') {
   return `${'https://www.ciacosinte.site/crm/ws/blog_ws.php?api='}${path}`;
   //return `${process.env.REACT_APP_STRAPI_API_URL || 'http://35.231.16.140:1337'}${path}`;
}

/**
 * Retorna la URL completa de una imagen de Strapi
 * Si la imagen es externa, retorna la URL completa
 * Si la imagen es interna, retorna la URL completa de Strapi + la ruta de la imagen
 * @param {*} url
 * @returns
 */

export function getStrapiMedia(url) {
    if (url == null) {
        return null;
    }
    if (url.startsWith('http') || url.startsWith('//')) {
        return url;
    }
    return `${getStrapiURL()}${url}`;
}

/**
 * Formatea una fecha en formato local
 * @param {*} dateString
 * @returns
 */

export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es-CO', options);
}