import React from "react";
import Image from "react-bootstrap/Image";
import { getStrapiMedia, formatDate } from "../../utils/api-helpers";
import { Link } from "react-router-dom";
import defaultImage from "../../assets/img/operaciones.webp";
import "./PostList.scss";

export default function PostList({ data: articles, children }) {
    return (
        <section className="mt-5">
            <div className="listapost__container">
                {articles?.map((article) => {
                    const imageUrl = getStrapiMedia(
                        article?.attributes?.cover?.data?.attributes.url
                    );

                    const categories = article?.attributes?.categories?.data;
                    const authorsBio =
                        article.attributes?.authorsBio?.data?.attributes;

                    const avatarUrl = getStrapiMedia(
                        authorsBio?.avatar.data?.attributes.url
                    );
                    return (
                        <Link
                            to={`/blog/${article.attributes.slug}`}
                            key={article.id}
                            className="post__container"
                        >
                            <div className="post__img__container">
                                {imageUrl ? (
                                    <Image
                                        alt="post__img"
                                        width="240"
                                        height="240"
                                        className="post__img"
                                        src={imageUrl}
                                    />
                                ) : (
                                    <Image
                                        alt="post__img"
                                        width="240"
                                        height="240"
                                        className="post__img"
                                        src={defaultImage}
                                    />
                                )}

                                <div className="post__categories__container">
                                    {categories &&
                                        categories.map((category) => {
                                            return (
                                                <span
                                                    key={category.id}
                                                    className="post__category"
                                                >
                                                    {category.attributes.name}
                                                </span>
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="post__info">
                                <div>
                                    <h3 className="post__titulo">
                                        {article.attributes.title}
                                    </h3>
                                    <p className="post__description">
                                        {article.attributes.description}
                                    </p>
                                </div>
                                <div className="post__about">
                                    <div className="post__author">
                                        {avatarUrl && (
                                            <Image
                                                alt="avatar"
                                                width="80"
                                                height="80"
                                                src={avatarUrl}
                                                className="post__author__avatar"
                                            />
                                        )}

                                        <span className="post__author__name">
                                            {authorsBio && (
                                                <span className="post__author__name">
                                                    {authorsBio.name}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                    <span className="post__date">
                                        {formatDate(
                                            article.attributes.publishedAt
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
            {children && children}
        </section>
    );
}
