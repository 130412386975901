import { ThemeProvider } from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect} from "react";
import Inicio from "./views/Inicio.js";
import Nosotros from "./views/Nosotros.js";
import Servicios from "./views/Servicios.js";
import Servicio from "./views/Servicio.js";
import Contactenos from "./views/Contactenos.js";
import TrabajeConNosotros from "./views/TrabajeConNosotros.js";
import NotFound from "./views/NotFound.js";
import Blog from "./views/Blog.js";
import { ParallaxProvider } from "react-scroll-parallax";
import ScrollToTop from "./components/common/ScrollToTop.js";
import Splash from "./components/common/Splash.js";
import "intersection-observer";
import MouseFollower from "mouse-follower";
import gsap from "gsap";
import BlogLayout from "./components/blog/layouts/BlogLayout.js";
import Categoria from "./views/Categoria.js";
import PostAPI from "./views/PostAPI.js";

MouseFollower.registerGSAP(gsap);
function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cursor = new MouseFollower({
            el: null,
            container: document.querySelector("#root"),
            className: "cursor__outside",
            innerClassName: "cursor",
            textClassName: "cursor__text",
            mediaClassName: "mf-cursor-media",
            mediaBoxClassName: "mf-cursor-media-box",
            iconSvgClassName: "mf-svgsprite",
            iconSvgNamePrefix: "-",
            iconSvgSrc: "",
            dataAttr: "cursor",
            hiddenState: "-hidden",
            textState: "-text",
            iconState: "-icon",
            activeState: "-active",
            mediaState: "-media",
            stateDetection: {
                "-pointer": "a,button",
                "-hidden": "iframe",
            },
            visible: true,
            visibleOnState: false,
            speed: 0.55,
            ease: "expo.out",
            overwrite: true,
            skewing: 0,
            skewingText: 2,
            skewingIcon: 2,
            skewingMedia: 2,
            skewingDelta: 0.001,
            skewingDeltaMax: 0.15,
            stickDelta: 0.15,
            showTimeout: 20,
            hideOnLeave: true,
            hideTimeout: 300,
            hideMediaTimeout: 300,
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    const theme = {
        buttons: {
            form: {
                color: "white",
                backgroundColor: "#0561AA",
                borderRadius: "5px",
                "&:hover": {
                    backgroundColor: "#FFCC33",
                },
            },
            primary: {
                color: "white",
                backgroundColor: "#2A3E67",
                borderRadius: "4px",
                "&:hover": {
                    backgroundColor: "#E6C55B",
                    color: "#2A3E67",
                },
            },
            servicio: {
                color: "var(--color-primary-800)",
                backgroundColor: "white",
                border: "1px solid var(--color-primary-800)",
                borderRadius: "4px",
                "&:hover": {
                    backgroundColor: "var(--color-primary-800)",
                    color: "white",
                },
            },
            hero: {
                color: "white",
                backgroundColor: "transparent",
                fontSize: "18px",
                border: "2px solid white",
                padding: "15px 35px",
                borderRadius: "4px",
                zIndex: "1",
                "&:hover": {
                    backgroundColor: "white",
                    boxShadown: "inset 400px 0 0 0 #D80286",
                    color: "var(--color-font)",
                },
            },
            blog:{
                color: "#22315f",
                backgroundColor:"#e6eeff",
                borderRadius: "4px",
                "&:hover": {
                    backgroundColor:"#22315f",
                    color:"#d3d6df"
                }
            }
        },
        buttonSizes: {
            small: {
                padding: `7px 15px`,
            },
            medium: {
                padding: `12px 24px`,
            },
            large: {
                padding: `15px 35px`,
            },
        },
    };

    return (
        <ThemeProvider theme={theme}>
            <Splash isLoaded={!loading} />
            <ParallaxProvider>
                <BrowserRouter basename="/">
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<Inicio />} />
                        <Route path="/inicio" element={<Inicio />} />
                        <Route path="/nosotros" element={<Nosotros />} />
                        <Route path="/servicios" element={<Servicios />} />
                        <Route path="/servicios/:id" element={<Servicio />} />
                        <Route path="/contactenos" element={<Contactenos />} />
                        <Route
                            path="/trabaje-con-nosotros"
                            element={<TrabajeConNosotros />}
                        />
                        <Route path="/blog" element={<BlogLayout><Blog></Blog></BlogLayout>}/>
                        <Route path="/blog/categoria/:category" element={<BlogLayout><Categoria/></BlogLayout>} />
                        <Route path="/blog/:slug" element={<BlogLayout><PostAPI></PostAPI></BlogLayout>} />
                        <Route path="/404" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </ParallaxProvider>
        </ThemeProvider>
    );
}

export default App;
