import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import TituloSeccion from "../common/TituloSeccion";
import TarjetaVertical from "../tarjetas/TarjetaVertical";
import confiabilidad from "../../assets/img/confiabilidad.gif";
import seguridadIntegral from "../../assets/img/seguridad_integral.gif";
import calidad from "../../assets/img/calidad.gif";
import Pdf_Tratamientos_Datos from "../../assets/docs/Politica-de-Tratamiento-de-Datos-2023.pdf";
import Pdf_Gestion_Integral from "../../assets/docs/Politica-de-Gestion-Integral-2023.pdf";
import Pdf_Mision_Vision from "../../assets/docs/Mision-y-Vision-2023.pdf";
import CustomButton from "../common/CustomButton";
import ExpandSplit from "../valores/expandSplit/ExpandSplit";
import { useNavigate } from "react-router-dom";
import "./Valores.scss";

export default function Valores() {
    const navigate = useNavigate();
    return (
        <Container fluid className={"mt-0 pt-5 px-0"}>
            <div className={"valores__inner__container"}>
                <TituloSeccion
                    icono="\e06c"
                    tituloSize="var(--fs-t3)"
                    titulo={
                        <span>
                            {" "}
                            Deje su seguridad en manos expertas,
                            <br /> nosotros trabajamos para usted{" "}
                        </span>
                    }
                    subtitulo={
                        "Auditamos procesos, detectamos riesgos, calculamos las pérdidas, diseñamos estrategias y ejecutamos planes de acción."
                    }
                ></TituloSeccion>
                <ExpandSplit></ExpandSplit>

                {/*
                <Row className="mb-5 pb-5 d-flex">
                    <Col md={4}>
                        <TarjetaVertical
                            titulo={"CONFIABILIDAD"}
                            imagen={confiabilidad}
                            descripcion={
                                "Le garantizamos a nuestros clientes que su información y la de su empresa está en manos profesionales. Nuestra gestión no solo está amparada por un acuerdo de confidencialidad, sino también por una política de tratamiento de datos personales. Descargue nuestra Política de Tratamiento de Datos Personales:"
                            }
                            boton={"Descargar"}
                            pdf={Pdf_Tratamientos_Datos}
                        />
                    </Col>
                    <Col md={4}>
                        <TarjetaVertical
                            titulo={"SEGURIDAD INTEGRAL"}
                            imagen={seguridadIntegral}
                            descripcion={
                                "Nos encargamos de aplicar la normatividad vigente, junto con la metodología avalada en el ámbito de la gestión integral de riesgos. Nuestro compromiso es prevenir el delito y administrar el riesgo teniendo en cuenta sus necesidades. Descargue nuestra Política de Gestión Integral:"
                            }
                            boton={"Descargar"}
                            pdf={Pdf_Gestion_Integral}
                        />
                    </Col>
                    <Col md={4}>
                        <TarjetaVertical
                            titulo={"CALIDAD"}
                            imagen={calidad}
                            descripcion={
                                "Contamos con una infraestructura propia y personal idóneo para la adecuada gestión de riesgos; nuestra plataforma tecnológica CIA®2.0 está desarrollada con tecnología de punta, la cual garantiza una mayor eficacia y eficiencia en todos nuestros procesos. Descargue nuestra Misión y Visión:"
                            }
                            boton={"Descargar"}
                            pdf={Pdf_Mision_Vision}
                        />
                    </Col>
                </Row>
                */}
            </div>
        </Container>
    );
}
