import React, {useRef, useLayoutEffect} from "react";
import tituloImg from "../../assets/img/titulo-mitad.svg";
import { Container, Image } from "react-bootstrap";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./TituloPagina.scss";

gsap.registerPlugin(ScrollTrigger);

export default function TituloPagina({titulo, escala = 1, minHeight }) {
  const titleRef = useRef(null);

  useLayoutEffect(() => {
      scrollGsap();
  });

  function scrollGsap() {
    const element = titleRef.current;
    gsap.fromTo(
      element,
      { x: "0" , opacity: 1, scale: escala},
      {
        x: "-100",
        opacity: 0.5,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: element,
          scrub: 1,
          markers: false,
          start: "center 10%",
          end: "bottom 100%",
        },
      }
    );
  }

  return (
    <div className="clearfix__navbar container__titulo">
      <Container>
        <h1 className="texto" ref={titleRef}>{titulo}</h1>
      </Container>
      <Image src={tituloImg} alt="Titulo pagina" className={`titulo__imagen ${minHeight?"min-100vh":""}`} />
    </div>
  );
}
