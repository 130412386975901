import React, {useEffect, useRef, useState} from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import './CountItem.scss';

export default function CountItem({number, text, Icon, suffix}) {
  const [startNumber, setStartNumber] = useState(0);
  const [endNumber, setEndNumber] = useState(number);

  useEffect(() => {
    setEndNumber(number);
  }, [number]);

  const handleCountEnd = async () => {
    //Await 2 seconds
    await new Promise(resolve => setTimeout(resolve, 1000));
    setStartNumber(endNumber);
    setEndNumber(startNumber);
  };

  return (
    <div>
      <CountUp
        start={startNumber}
        end={endNumber}
        duration={3}
        separator="."
        suffix={suffix}
        onEnd={() => handleCountEnd()}>
        {({countUpRef, start}) => (
          <VisibilitySensor onChange={start} delayedCall>
            <div className="count__container">
              <div className="count__number__container">
                {Icon && <Icon className="count__icon"></Icon>}
                <span className="count__number" ref={countUpRef} />
              </div>
              <h3 className="count__text">{text}</h3>
            </div>
          </VisibilitySensor>
        )}
      </CountUp>
    </div>
  );
}
