import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/api-helpers";
import "./ArticleSelect.scss";
import { a } from "@react-spring/web";

function selectedFilter(current, selected) {
    return current === selected
        ? "categoria__item categoria__item--active"
        : "categoria__item";
}

export default function ArticleSelect({ categories, articles, params }) {

    const getTotalArticles = () => {
        const articles = new Set();
        categories?.forEach((category) => {
            category?.attributes?.articles?.data.forEach((article) => {
                articles.add(article.id);
            });
        });
        return articles.size;
    };

    return (
        <div className="mt-5">
            <div>
                <div className="box__layout">
                    <h4 className="layout__box__title">Categorías</h4>
                    <div className="lista__categorias">
                        {categories?.map((category) => {
                            if (
                                category?.attributes?.articles?.data.length ===
                                0
                            )
                                return null;
                            return (
                                <Link
                                    to={`/blog/categoria/${category?.attributes.slug}`}
                                    className={selectedFilter(
                                        category.attributes?.slug,
                                        params?.category
                                    )}
                                >
                                    <span>{category?.attributes.name}</span>
                                    <span>{`(${category?.attributes?.articles?.data.length})`}</span>
                                </Link>
                            );
                        })}

                        <Link
                            to={"/blog"}
                            className={selectedFilter(
                                undefined,
                                params?.category
                            )}
                        >
                            <span>Todos</span>
                            <span>{`(${getTotalArticles()})`}</span>
                        </Link>
                    </div>
                </div>

                {articles?.length > 0 && (
                    <div className="box__layout">
                        <h4 className="layout__box__title mt-3">
                            Otros artículos de interés
                        </h4>
                        <ul className="articulos__relacionados">
                            {articles?.map((article) => {
                                return (
                                    <li key={article.id} className="articulo__item">
                                        <Link
                                            rel="noopener noreferrer"
                                            to={`/blog/${article.attributes.slug}`}
                                            className={"articulo__link"}
                                        >
                                            <span className="articulo__fecha">
                                                {formatDate(
                                                    article.attributes.publishedAt
                                                )}
                                            </span>
                                            <span className="articulo__titulo">
                                                {article.attributes.title}
                                            </span>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}
