import React from "react";
import { formatDate, getStrapiMedia } from "../../utils/api-helpers";
import { postRenderer } from "./PostRenderer";
import Image from "react-bootstrap/Image";
import { Calendar, Tag } from "lucide-react";
import "./Post.scss";
import Comments from "./Comments";

export default function Post({ data }) {
    const { publishedAt, cover, authorsBio } =
        data?.attributes;
    const author = authorsBio?.data?.attributes;
    const imageUrl = getStrapiMedia(cover?.data?.attributes.url);
    const authorImgUrl = getStrapiMedia(
        authorsBio?.data?.attributes?.avatar?.data?.attributes?.url
    );

    return (
        <article className="post__article mt-5">
            {imageUrl && (
                <Image
                    src={imageUrl}
                    alt="article cover image"
                    className="post__cover__image"
                />
            )}
            <div className="post__header">
                <div className="post__info">
                    <div className="post__author">
                        {authorImgUrl && (
                            <Image
                                src={authorImgUrl}
                                alt="article cover image"
                                width={400}
                                height={400}
                                className="post__author__avatar"
                            />
                        )}
                        <span className="post__author__name">
                            {author && author.name}
                        </span>
                    </div>
                    <div className="post__date">
                        <Calendar size={20} />
                        <span>{formatDate(publishedAt)}</span>
                    </div>
                    <div className="post__category">
                        <Tag size={20} />
                        <span>
                            {data.attributes?.categories?.data &&
                                data.attributes.categories.data.map(
                                    (category, index) => (
                                        <span key={category.id}>
                                            {category.attributes.name}
                                            {index <
                                                data.attributes.categories.data
                                                    .length -
                                                    1 && ", "}
                                        </span>
                                    )
                                )}
                        </span>
                    </div>
                </div>
            </div>

            <div className="dark:text-gray-100">
                {data?.attributes?.blocks?.map((section, index) =>
                    postRenderer(section, index)
                )}
            </div>

            <div className="post__footer">
                <Comments articleId={data.id} />
            </div>
        </article>
    );
}
