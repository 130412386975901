import { fetchAPI } from '../utils/fetch-api'
import PostList from '../components/blog/PostList';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function Categoria(props) {
    const [data, setData] = useState([]);
    const params = useParams();

    /**
     * Obtener los datos de los artículos por categoría
     * @param {string} filter Slug de la categoría
     */

    async function fetchPostsByCategory(filter) {
        try {
            const token = process.env.REACT_APP_STRAPI_API_TOKEN;
            const path = `/articles`;
            const urlParamsObject = {
                sort: { createdAt: 'desc' },
                filters: {
                    categories: {
                        slug: filter,
                    },
                },
                populate: {
                    cover: { fields: ['url'] },

                    authorsBio: {
                        populate: '*',
                    },
                },
            };
            const options = { headers: { Authorization: `Bearer ${token}` } };
            const responseData = await fetchAPI(path, urlParamsObject, options);
            return responseData;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Obtener el nombre de la categoría a partir del slug
     */

    async function fetchCategoryName() {
        try {
            const token = process.env.REACT_APP_STRAPI_API_TOKEN;
            const path = `/categories`;
            const urlParamsObject = {
                filters: {
                    slug: params?.category,
                },
            };
            const options = { headers: { Authorization: `Bearer ${token}` } };
            const {data} = await fetchAPI(path, urlParamsObject, options);
            props.setHeader({
                titulo: data[0]?.attributes.name,
                subtitulo: data[0]?.attributes.description,
            });
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        async function fetchData() {
            const filter = params?.category;
            const { data } = await fetchPostsByCategory(filter);
            if (data.length === 0) {
                return;
            }
            setData(data);
        }

        fetchData();
        fetchCategoryName();
    }, [params?.category]);

    if (data.length === 0) {
        return <div>No hay artículos en esta categoría</div>;
    }

    return (
        <div>
            <PostList data={data} />
        </div>
    );
}

export default Categoria;