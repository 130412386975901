import React from 'react';
import './Loader.scss';

export default function Loader({mensaje}) {
  return (
    <div className="loader-container">
      <span class="loader"></span>
      <span className="loader-texto">{mensaje}</span>
    </div>
  );
}
