import React from "react";
import styled from "styled-components";
import "./CardServicio.scss";

export default function CardServicio({ titulo, descripcion, Icono }) {
    return (
        <Contenedor>
            <ContenedorIcono>{Icono}</ContenedorIcono>
            <Titulo>{titulo}</Titulo>
            <Descripcion>{descripcion}</Descripcion>
        </Contenedor>
    );
}

const ContenedorIcono = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    background-color: var(--color-gray-200);
    border-radius: 50%;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    border: 8px solid #eeeff2;
    background: #dee0e7;
    padding: 0.5rem;

    svg {
        color: var(--color-gray-600);
        font-size: 1.5rem;
    }
`;

const Contenedor = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid var(--color-gray-200);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-in-out;

    &::before,
    &::after {
        box-sizing: inherit;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &:before,
    &:after {
        border-radius: 10px;
        border: 1px solid transparent;
        width: 0;
        height: 0;
    }

    &::before {
        top: 0;
        left: 0;
    }

    &::after {
        bottom: 0;
        right: 0;
    }

    &:hover {
        color: var(--color-secondary);
        background-color: #FEFDF9;
        scale: 1.05;
    }

    &:hover::before,
    &:hover::after {
        width: 100%;
        height: 100%;
    }

    &:hover::before {
        border-top-color: #E6C55B; // Make borders visible
        border-right-color: #E6C55B;
        transition: width 0.25s ease-out,
            // Width expands first
            height 0.25s ease-out 0.25s; // And then height
    }

    &:hover::after {
        border-bottom-color: #E6C55B; // Make borders visible
        border-left-color: #E6C55B;
        transition: border-color 0s ease-out 0.5s,
            // Wait for ::before to finish before showing border
            width 0.25s ease-out 0.5s,
            // And then exanding width
            height 0.25s ease-out 0.75s; // And finally height
    }
`;

const Titulo = styled.h3`
    color: var(--color-gray-800);
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
`;

const Descripcion = styled.p`
    color: var(--color-gray-600);
    font-weight: 400;
    text-align: center;
    margin-bottom: 1rem;
`;
