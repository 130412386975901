import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import IndicadorPagina from "../../indicadorPagina/IndicadorPagina";
import CustomButton from "../../common/CustomButton";
import { useNavigate, useLocation } from "react-router-dom";
import "./ServicioInfo.scss";
import CardServicio from "./cardServicio/CardServicio";
import styled from "styled-components";
import Button from "../../common/Button";
import { HelpCircle, ClipboardCheck, Search } from 'lucide-react'

export default function ServicioInfo({ servicio }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = () => {
        if (location.pathname === "/servicios/analisis-sargrilaft/") {
            navigate("/contactenos");
        }else{
            navigate("/servicios/analisis-sargrilaft/");
        }
    };
    return (
        <Container fluid className="p-0 clearfix__navbar">
            {servicio && (
                <>
                    <ContendorImagen
                        src={require(`../../../assets/img/${servicio.imagen.src}`)}
                    >
                        <Container className="servicio__container">
                            <Slide
                                className="servicio__info"
                                triggerOnce={true}
                            >
                                <span className="servicio__categoria">
                                    {servicio.categoria}
                                </span>
                                <h1 className="servicio__titulo">
                                    {servicio.nombre}
                                </h1>
                                <p className="servicio__descripcion">
                                    {servicio.descripcion}
                                </p>
                                <d iv className="mt-5">
                                    <Button
                                        variant="hero"
                                        onClick={() => navigate("/contactenos")}
                                    >
                                        Contáctenos
                                    </Button>
                                </d>
                            </Slide>
                        </Container>
                    </ContendorImagen>
                    <Container className="d-flex flex-column align-items-center">
                        <Row className="mt-5 pt-0 pt-lg-3">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-column align-items-center mb-3 mb-lg-0"
                            >
                                <CardServicio
                                    titulo={"¿Qué es?"}
                                    descripcion={servicio?.detalle[0]}
                                    Icono={<HelpCircle size={24} color="currentColor" />}
                                ></CardServicio>
                            </Col>
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-column align-items-center mb-3 mb-lg-0"
                            >
                                <CardServicio
                                    titulo={"¿Cómo?"}
                                    descripcion={servicio?.detalle[1]}
                                    Icono={<Search size={24} color="currentColor" />}
                                ></CardServicio>
                            </Col>
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-column align-items-center mb-3 mb-lg-0"
                            >
                                <CardServicio
                                    titulo={"¿Qué obtendrá?"}
                                    descripcion={servicio?.detalle[2]}
                                    Icono={<ClipboardCheck size={24} color="currentColor" />}
                                ></CardServicio>
                            </Col>
                        </Row>
                        <div className="my-5 pt-5">
                            <CustomButton
                                texto={"¿QUIERE IMPLEMENTAR SAGRILAFT?"}
                                size={"lg"}
                                onClick={handleNavigation}
                            />
                        </div>
                        <IndicadorPagina></IndicadorPagina>
                    </Container>
                </>
            )}
        </Container>
    );
}

const ContendorImagen = styled.div`
    width: 100%;
    position: relative;
    background-color: var(--color-primary);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    padding: 5rem 0;
    overflow: hidden;
    background-image: url(${(props) => props.src});

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #101F39 1.15%, rgba(33, 50, 101, 0.00) 99.88%), rgba(0, 0, 0, 0.4) 50% / cover no-repeat;
        z-index: 1;
`;
