import React from 'react'
import Navegacion from '../components/Navegacion/Navegacion'
import Footer from '../components/Footer/Footer'
import NotFoundMessage from '../components/notfoundmessage/NotFoundMessage';

export default function NotFound() {
  return (
    <>
      <Navegacion />   
      <div className="clearfix__navbar"></div>
      <NotFoundMessage/>
      <Footer />
    </>
  );
}
