import React, { useState } from "react";
import Button from "../../common/Button";
import axios from "axios";
import Loader from "../../common/Loader";
import { Toast } from "react-bootstrap";
import politica from "../../../assets/docs/Politica-de-Tratamiento-de-Datos-2023.pdf";
import ReCAPTCHA from "react-google-recaptcha";
import "./Form.scss";

function Form() {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formMessage, setFormMessage] = useState(null);
    const [formData, setFormData] = useState({
        nombre: "",
        email: "",
        asunto: "",
        telefono: "",
        mensaje: "",
    });

    /**
     * Método que se ejecuta cuando se envía el formulario
     * @param {*} e - Evento que se dispara cuando se envía el formulario
     */

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setError(false);
        setSuccess(false);
        setFormMessage(null);
        if (!token) {
            setError(true);
            setFormMessage("Por favor, verifique el captcha");
            setLoading(false);
            return;
        }

        axios
            .post("api/ecc.php", {
                nombre: formData.nombre,
                email: formData.email,
                asunto: formData.asunto,
                telefono: formData.telefono,
                mensaje: formData.mensaje,
                token: token,
            })
            .then((res) => {
                if (res.data.success) {
                    setSuccess(true);
                    setFormMessage(res.data.message);
                    setLoading(false);
                    handleReset();
                } else {
                    setError(true);
                    setFormMessage(res.data.message);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setError(true);
                setLoading(false);
            });
    };

    /**
     * Actualiza el estado de los inputs
     * @param {*} e  - Evento que se dispara cuando se cambia el valor de un input
     */

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    /**
     * Resetea el formulario y los inputs
     */

    const handleReset = () => {
        setFormData({
            nombre: "",
            email: "",
            asunto: "",
            telefono: "",
            mensaje: "",
        });

        //Clear all inputs
        const inputs = document.querySelectorAll("input");
        inputs.forEach((input) => (input.value = ""));
        const textarea = document.querySelector("textarea");
        textarea.value = "";
    };

    /**
     * Método que se ejecuta cuando se cambia el captcha
     */

    const onChangeCaptcha = (value) => {
        setToken(value);
    };

    return (
        <div>
            <form
                className={"form mx-auto mx-lg-0"}
                onSubmit={handleFormSubmit}
            >
                <input
                    className="form__input"
                    type="text"
                    placeholder="*Nombre y Apellido"
                    id="nombre"
                    name="nombre"
                    onChange={handleChange}
                    required
                />
                <input
                    className="form__input"
                    type="email"
                    placeholder="*Correo electrónico"
                    id="email"
                    name="email"
                    required
                    onChange={handleChange}
                />
                <input
                    className="form__input"
                    type="text"
                    placeholder="*Asunto"
                    id="asunto"
                    name="asunto"
                    required
                    onChange={handleChange}
                />
                <input
                    className="form__input"
                    type="tel"
                    placeholder="*Número de contacto"
                    id="telefono"
                    name="telefono"
                    required
                    onChange={handleChange}
                />
                <textarea
                    className="form__input form__input--textarea"
                    id="mensaje"
                    name="mensaje"
                    placeholder="*¿Cómo podemos ayudarlo?"
                    required
                    onChange={handleChange}
                />
                <div className="form__button_container">
                    <ReCAPTCHA
                        sitekey="6LfHMhIpAAAAAFZDVSu-dkAEY5WdN4BbcsHTmig3"
                        onChange={onChangeCaptcha}
                    />
                    <div>
                        <Button type="submit" variant="primary">
                            {loading ? (
                                <Loader mensaje={"Enviando"}></Loader>
                            ) : (
                                "Enviar"
                            )}
                        </Button>
                    </div>
                </div>

                {error && (
                    <Toast
                        onClose={() => setError(false)}
                        show={error}
                        delay={4000}
                        autohide
                        className="toast__error"
                    >
                        <Toast.Header>
                            <strong className="me-auto">Error</strong>
                        </Toast.Header>
                        <Toast.Body>{formMessage}</Toast.Body>
                    </Toast>
                )}

                {success && (
                    <Toast
                        onClose={() => setSuccess(false)}
                        show={success}
                        delay={4000}
                        autohide
                        className="toast__success"
                    >
                        <Toast.Header>
                            <img
                                src="holder.js/20x20?text=%20"
                                className="rounded me-2"
                                alt=""
                            />
                            <strong className="me-auto">Éxito</strong>
                        </Toast.Header>
                        <Toast.Body>{formMessage}</Toast.Body>
                    </Toast>
                )}
            </form>
            <div className="legal">
                <span className="legal__title">•AVISO LEGAL</span>
                <p className="legal__text">
                    *Al dar clic sobre el botón de ENVIAR, estoy aceptando el
                    tratamiento de mis datos personales conforme a la
                    <a
                        className="legal__link"
                        href={politica}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {" "}
                        política de tratamiento de datos personales.
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Form;
