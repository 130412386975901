import React from 'react'
import "./CustomButton.scss";

export default function CustomButton({texto, size, ...props}) {
  return (
<div {...props}>
    <div href="#" class={`button button--${size}`}>
      <div className="button__line"></div>
      <div className="button__line"></div>
      <span className="button__text">{texto}</span>
      <div className="button__drow1"></div>
      <div className="button__drow2"></div>
    </div>
  </div>
  )
}
