import React from 'react';
import {ReactComponent as Linkedin} from '../../../assets/icon/linkedin.svg';
import {ReactComponent as Facebook} from '../../../assets/icon/facebook.svg';
import {ReactComponent as Instagram} from '../../../assets/icon/instagram.svg';
import {Link} from 'react-router-dom';
import './Enlaces.scss';

export default function Enlaces() {
  return (
    <div className="links__container">
      <h3 className="links__title">Nuestro sitio web</h3>
      <ul className="links__list">
        <li className="link__container">
          <Link to="/inicio" className="link__link">
            Inicio
          </Link>
        </li>
        <li className="link__container">
          <Link to="/nosotros" className="link__link">
            Nosotros
          </Link>
        </li>
        <li className="link__container">
          <Link to="/servicios" className="link__link">
            Servicios
          </Link>
        </li>
        <li className="link__container">
          <Link to="/contactenos" className="link__link">
            Contáctenos
          </Link>
        </li>
        <li className="link__container">
          <Link to="/trabaje-con-nosotros" className="link__link">
            Trabaje con nosotros
          </Link>
        </li>
      </ul>

      <div className="social__container">
        <Link to={'https://www.instagram.com/cosinteltda/'} target="_blank">
          <Instagram className="social__icon social__icon--contraste"></Instagram>
        </Link>
        <Link to={'https://www.facebook.com/Cosinteltda/'} target="_blank">
          <Facebook className="social__icon"></Facebook>
        </Link>
        <Link
          to={'https://www.linkedin.com/company/cosinte-ltda./'}
          target="_blank">
          <Linkedin className="social__icon"></Linkedin>
        </Link>
      </div>
    </div>
  );
}
