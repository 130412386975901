import React from "react";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { ArrowRight, ChevronRight } from "lucide-react";
import MouseIndicator from "../common/MouseIndicator";
import "./TarjetaHorizontal.scss";

export default function TarjetaHorizontal({
    titulo,
    imagen,
    subtitulo,
    descripcion,
    boton,
    scrollIndicator,
}) {
    const navigate = useNavigate();

    return (
        <div className="card__container__horizontal">
            <ImageContainer imagen={imagen}></ImageContainer>
            <div className="content__container">
                <Container className="inner__container">
                    <h3 className="title__card">{titulo}</h3>
                    {subtitulo && (
                        <h2 className="subtitle__card">{subtitulo}</h2>
                    )}
                    {descripcion && (
                        <div className="description__card">{descripcion}</div>
                    )}
                    <div
                        className="d-flex justify-content-center"
                        onClick={() => navigate("/servicios")}
                    >
                        {boton && <Button variant={"hero"}>{boton}</Button>}
                    </div>

                    {scrollIndicator && (
                        <div className="scroll__indicator">
                            <span className="scroll__message">
                                Conozca nuestro portafolio de servicios <br></br> Haga clic en    <ChevronRight size={24} color="white" /> para continuar
                            </span>
                            <MouseIndicator />
                        </div>
                    )}
                </Container>
            </div>
        </div>
    );
}

const ImageContainer = styled.div`
    object-fit: cover;
    margin-bottom: 0;
    width: 100vw;
    height: 100vh;
    background-image: url(${(props) => props.imagen});
    background-size: cover;
    background-position: center;

`;
