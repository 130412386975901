import React from "react";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import { Container } from "react-bootstrap";

export default function TituloSeccion({
  icono,
  titulo,
  subtitulo,
  tituloSize,
  iconoSize,
  subtituloSize,
  subtituloChildren,
  isLeftAlign,
  svgIcon,
  ...props
}) {
  return (
    <ContainerTitulo {...props} isLeftAlign={isLeftAlign}>
      <Fade duration="2000" triggerOnce={true}>
        {icono && <Icono icono={icono} iconoSize={iconoSize} />}
      </Fade>
      <Container>
        <Titulo $tituloSize={tituloSize} isLeftAlign={isLeftAlign}>{titulo}</Titulo>
        {subtitulo && (
          <Subtitulo isLeftAlign={isLeftAlign} subtituloSize={subtituloSize} >{subtitulo}</Subtitulo>
        )}
      </Container>
    </ContainerTitulo>
  );
}

const ContainerTitulo = styled.div`
  display: flex;
  align-items: ${({ isLeftAlign }) => (isLeftAlign ? "flex-start" : "center")};
  justify-content: center;
  flex-direction: column;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0")};

  svg {
    width: 80px;
    height: 80px;
    color: var(--color-secondary-300);
    margin-bottom: 1rem;
    margin: 30px;
  }
`;

const Titulo = styled.h2`
  font-weight: 700;
  font-size: ${({ $tituloSize }) => ($tituloSize ? $tituloSize : "22px")};
  color: var(--color-primary-700);
  line-height: 1.2em;
  line-height: 1.2em;
  text-align: ${({ isLeftAlign }) => (isLeftAlign ? "left" : "center")};
  white-space: pre-line;

  //Centrar cuando es tablet
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Subtitulo = styled.p`
  color: var(--color-font-light);
  text-align: ${({ isLeftAlign }) => (isLeftAlign ? "left" : "center")};
  font-size: ${({ subtituloSize }) => (subtituloSize ? subtituloSize : "18px")};
  font-weight: 300;
  max-width: 100%;
  margin: ${({ isLeftAlign }) => (isLeftAlign ? "1rem 0 " : "2rem auto")};
  white-space: pre-line;

  //Centrar cuando es tablet
  @media (max-width: 768px) {
    text-align: center;
  }

`;

const Icono = styled.span`
  margin: 0 0.5rem;
  font-family: "etmodules";
  font-size: ${({ iconoSize }) => (iconoSize ? iconoSize : "65px")};
  color: var(--color-secondary-300);
  margin-bottom: 1rem;

  &::before {
    content: ${({ icono }) => `"${icono}"`};
  }
`;
