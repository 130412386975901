import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimationControls } from "framer-motion";
import { Image } from "react-bootstrap";
import { Zoom, Reveal } from "react-awesome-reveal";
import logo from "../../assets/logo-cosinte-blanco.png";

export default function Splash({ isLoaded }) {
  const controls = useAnimationControls();
  const controlsImage = useAnimationControls();
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);

  /**
   * Cuando indquemos que la página está cargada,
   * Desvanecemos el splash
   */

  useEffect(() => {
    if (isLoaded) {
      controls.start("hidden");
      controlsImage.start("zoomOut");
      setTimeout(() => {
        setIsAnimationFinished(true);
      }, 500);
    }
  }, [isLoaded, controlsImage, controls]);

  return (
    !isAnimationFinished && (
      <SplashContainer
        as={motion.div}
        initial="visible"
        animate={controls}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        transition={{ duration: 0.5 }}
      >
        <Zoom duration="2000" triggerOnce={true} className="mx-auto">
          <motion.div  initial="normal" animate={controlsImage} variants={{
            normal: { scale: 1},
            zoomOut: { scale: 0}
          }}>
            <Imagen src={logo} alt="Logo Cosinte LTDA" />
          </motion.div>
        </Zoom>
      </SplashContainer>
    )
  );
}

const SplashContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(11, 31, 59, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483654;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: opacity 1s ease-in-out;
  opacity: 1;
`;

const Imagen = styled(Image)`
  width: 30%;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
  display: block;
  //En pantallas pequeñas debe ocupar el 80% del ancho
  @media (max-width: 768px) {
    width: 80%;
  }
`;
