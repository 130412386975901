import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountItem from "./countItem/CountItem";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import "./Historia.scss";
import CustomButton from "../common/CustomButton";

export default function Historia() {
  const navigate = useNavigate();
  
  // Año de fundación y fecha de inicio
  const foundationYear = 1996; // Año de fundación
  const foundationDate = new Date(foundationYear, 6, 24); // 24 de julio de 1996 (mes es 0-indexado)
  const currentDate = new Date();
  
  // Calcula los años de trayectoria
  let yearsInBusiness = currentDate.getFullYear() - foundationYear;

  // Verifica si la fecha actual es antes o después del 24 de julio
  if (currentDate < foundationDate) {
    yearsInBusiness--; // Reduce un año si la fecha actual es antes de la fecha de inicio
  }

  return (
    <Container>
      <div className="d-flex flex-column align-items-center justify-content-between p-5">
        <div className="d-flex flex-column justify-content-center">
          <Zoom duration="2000" triggerOnce={true} className="mb-4">
            <h4 className="titulo__seccion__historia ">
              ¿Necesita comunicarse con nosotros?
            </h4>
          </Zoom>
          <div className="text-center mb-5 mx-auto">
            <CustomButton
              texto={"Contáctenos"}
              onClick={() => {
                navigate("/contactenos");
              }}
            ></CustomButton>
          </div>
        </div>
        <Row className="mt-5 pt-5">
          <Col xs={12} md={6} lg={6} xl={3}>
            <CountItem
              number={yearsInBusiness} // Usamos el valor calculado
              text={"Años de trayectoria en el mercado"}
            ></CountItem>
          </Col>
          <Col xs={12} md={6} lg={6} xl={3}>
            <CountItem
              number={32}
              text={"Departamentos de Colombia en donde tenemos presencia"}
            ></CountItem>
          </Col>
          <Col xs={12} md={6} lg={6} xl={3}>
            <CountItem
              number={5000}
              suffix={"+"}
              text={"Informes realizados en el mes"}
            ></CountItem>
          </Col>
          <Col xs={12} md={6} lg={6} xl={3}>
            <CountItem
              number={6000}
              suffix={"+"}
              text={"Investigaciones administrativas realizadas en el mes"}
            ></CountItem>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
