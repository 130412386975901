import RichText from "./shared/RichText";

export function postRenderer(section, index) {
    switch (section.__component) {
        case "shared.rich-text":
            return <RichText data={section} key={index} />;
        default:
            return null;
    }
}
