import React, { useState, useEffect, useCallback } from "react";
import { fetchAPI } from "../utils/fetch-api"; // Función para hacer llamadas a la API de Strapi
import Loader from "../components/blog/Loader"; // Componente que muestra un loader
import PostList from "../components/blog/PostList"; // Componente que muestra la lista de artículos
import CustomButton from "../components/common/CustomButton";
import {Helmet} from 'react-helmet';

function Blog(props) {
    const [meta, setMeta] = useState(); // Contiene la información de paginación
    const [data, setData] = useState([]); // Contiene los datos de los artículos
    const [isLoading, setLoading] = useState(true); // Indica si se está cargando la página

    /**
     * Función para obtener los datos de los artículos
     * @param {*} start número de artículo desde el que se empieza a obtener datos
     * @param {*} limit número de artículos a obtener
     */

    const fetchData = useCallback(async (start, limit) => {
        setLoading(true);
        try {
            const token = process.env.REACT_APP_STRAPI_API_TOKEN;
            const path = `/articles`;
            const urlParamsObject = {
                sort: { createdAt: "desc" },
                populate: {
                    cover: { fields: ["url"] },
                    categories: { populate: "*" },
                    authorsBio: {
                        populate: "*",
                    },
                },
                pagination: {
                    start: start,
                    limit: limit,
                },
            };
            const options = { headers: { Authorization: `Bearer ${token}` } };
            const responseData = await fetchAPI(path, urlParamsObject, options);
            if (start === 0) {
                setData(responseData.data);
            } else {
                setData((prevData) => [...prevData, ...responseData.data]);
            }

            setMeta(responseData.meta);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []);

    /**
     * Función para obtener más artículos
     * Se ejecuta cuando se hace click en el botón "Load more posts..."
     */

    function loadMorePosts() {
        const nextPosts = meta.pagination.start + meta.pagination.limit;
        fetchData(nextPosts, Number(process.env.REACT_APP_STRAPI_PAGE_LIMIT));
    }

    /**
     * Se ejecuta al cargar la página y obtiene los primeros artículos
     * Acorde con la variable de entorno PUBLIC_PAGE_LIMIT (10 por defecto)
     */

    useEffect(() => {
        fetchData(0, Number(process.env.REACT_APP_STRAPI_PAGE_LIMIT));
    }, [fetchData]);

    useEffect(() => {
        props.setHeader({
            titulo: "CONOZCA LO ÚLTIMO DE COSINTE",
            subtitulo: "¿Quiere entender realmente por que prevenir   \n es la mejor forma de gestionar sus riesgos y ganar rentabilidad?",
        });
    },[]);

    return (
        <div>
    <Helmet>
        <title>Blog - Cosinte</title>
        <link
          rel="canonical"
          href="https://www.cosinte.com/blog"
        />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:title" content="Blog - Cosinte" />
        <meta
          property="og:url"
          content="https://www.cosinte.com/blog"
        />
        <meta property="og:image" content="../../assets/logo-cosinte-blanco.png" />
        <meta property="og:description" content="CONOZCA LO ÚLTIMO DE COSINTE
        ¿Quiere entender realmente por que prevenir es la mejor forma de gestionar sus riesgos y ganar rentabilidad?" />
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-999486799/TKebCLTrkdYYEM_qy9wD'});`}</script>
      </Helmet>
            {!isLoading ? (
                <PostList data={data}>
                    {meta?.pagination.start + meta?.pagination.limit <
                        meta?.pagination.total && (
                        <div className="d-flex justify-content-center mt-5">
                            <CustomButton
                                texto="Cargar más artículos..."
                                onClick={loadMorePosts}
                            >
                                Cargar más artículos...
                            </CustomButton>
                        </div>
                    )}
                </PostList>
            ) : (
                <Loader />
            )}
        </div>
    );
}

export default Blog;
