import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./RichText.scss";

export default function RichText({ data }) {
    //<Markdown children={data.body} remarkPlugins={[remarkGfm]} />
    return (
        <section className="rich__text">
            <Markdown children={data.body} remarkPlugins={[remarkGfm]} />
        </section>
    );
}
