import React from 'react';
import TarjetaBotones from './tarjetaBotones/TarjetaBotones';
import {Row, Col, Container} from 'react-bootstrap';
import servicios from '../../../data/servicios.json';

export default function botonesServicios() {
  const buscarPorCategoria = categoria => {
    const serviciosFiltrados = servicios.filter(servicio => {
      return servicio.categoria === categoria;
    });
    return serviciosFiltrados;
  };

  return (
    <Container className="w-75 mx-auto mt-5 pt-5">
      <Row sm={1} md={2} lg={4}>
        <Col>
          <TarjetaBotones
            icono="\e0e9"
            titulo="ANÁLISIS"
            botones={buscarPorCategoria('Análisis')}></TarjetaBotones>
        </Col>
        <Col>
          <TarjetaBotones
            icono="\e007"
            titulo="INVESTIGACIONES"
            botones={buscarPorCategoria('Investigaciones')}></TarjetaBotones>
        </Col>
        <Col>
          <TarjetaBotones
            icono="\e101"
            titulo="INTELIGENCIA"
            botones={buscarPorCategoria('Inteligencia')}></TarjetaBotones>
        </Col>
        <Col>
          <TarjetaBotones
            icono="\e038"
            titulo="OPERACIONES"
            botones={buscarPorCategoria('Operaciones')}></TarjetaBotones>
        </Col>
      </Row>
    </Container>
  );
}
